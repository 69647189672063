import { Component, Inject, OnInit } from '@angular/core';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Vehicle, VehiclesService } from '../../core/vehicles.service';
import { Resource } from '../../core/core.module';
import { Reservation } from '../../core/reservations.service';
import { ButtonService } from 'src/app/core/button.service';

@Component({
  selector: 'app-reservations-builder-vehicle',
  templateUrl: './reservations-builder-vehicle.component.html',
  styleUrls: ['./reservations-builder-vehicle.component.scss'],
})
export class ReservationsBuilderVehicleComponent implements OnInit {
  vehicleFormGroup: FormGroup;
  kmFormGroup: FormGroup;
  reservation: Resource;
  vehicle: Resource;
  vehicleContent: Vehicle;
  showButtonSpinner: boolean;

  constructor(
    private vehiclesService: VehiclesService,
    private dialogRef: MatDialogRef<ReservationsBuilderVehicleComponent>,
    private formService: FormService,
    public buttonService: ButtonService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.vehicleFormGroup = formService.reservationVehicleFormGroup;
    this.kmFormGroup = formService.reservationVehicleKmFormGroup;
    this.reservation = data.reservation;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.vehicleFormGroup.reset();
  }

  onSearchClick() {
    let searchText: string = this.vehicleFormGroup.get('plate').value;
    this.listAvailableVehicles(searchText);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.dialogRef.close({ success: true, vehicle: this.vehicle });
  }

  listAvailableVehicles(plate: string) {
    this.showButtonSpinner = true;
    let reservation: Reservation = this.reservation.content as Reservation;
    this.vehiclesService
      .listAvailableVehicles(reservation.start_date, reservation.end_date)
      .subscribe(
        (response) => {
          let vehicles: Resource[] = response.data;
          this.vehicle = vehicles.filter((v) => {
            return (
              (v.content as Vehicle).plate.toUpperCase() == plate.toUpperCase()
            );
          })[0];
          if (this.vehicle) {
            this.vehicleContent = this.vehicle.content as Vehicle;
          }
          this.showButtonSpinner = false;
        },
        (error) => {
          this.showButtonSpinner = false;
        }
      );
  }

  getButtonClass() {
    return {
      'irt-main-button': this.vehicle,
      'irt-main-disabled-button': !this.vehicle,
    };
  }
}
