import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from '../../core/core.module';
import {RESERVATIONS_PATH, ReservationState} from '../../shared/constants';
import {BehaviorSubject} from 'rxjs';
import {Reservation, ReservationsService, Service} from '../../core/reservations.service';
import {AuthService} from '../../core/auth.service';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {ReservationDetailEndContractComponent} from '../reservation-detail-end-contract/reservation-detail-end-contract.component';
import {Router} from '@angular/router';
import { ReservationDetailStatusComponent } from '../reservation-detail-status/reservation-detail-status.component';

@Component({
  selector: 'app-reservation-detail-section',
  templateUrl: './reservation-detail-section.component.html',
  styleUrls: ['./reservation-detail-section.component.scss']
})
export class ReservationDetailSectionComponent implements OnInit {
  @Input()  _reservation          : BehaviorSubject<Resource>;
  @Output() refresh               : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() deleteReservationClick: EventEmitter<void> = new EventEmitter<void>();
  reservationResource             : Resource;
  reservation                     : Reservation;
  showMenuSpinner                 : boolean = false;
  states                          : ReservationState[] = [
    ReservationState.PENDING, ReservationState.CONFIRMED,
    ReservationState.RUNNING, ReservationState.CLOSED, 
    ReservationState.EXPIRED
  ];

  constructor(private reservationsService : ReservationsService, 
              private authService         : AuthService,
              private dialogService       : DialogService, 
              private dialog              : MatDialog, 
              private router              : Router) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this._reservation.subscribe(
      reservation => {
        if (reservation) {
          this.reservationResource  = reservation;
          this.reservation          = reservation.content as Reservation;
        }
      }
    );
  }

  onRefreshClick() {
    this.refresh.emit(false);
  }

  onStatusClick() {
    this.dialogService.openDialog(ReservationDetailStatusComponent, { 
      status: this.reservationResource.status }).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success && dialogResult.status) {
          this.onStatusForceClick(dialogResult.status);
        }
      }
    );
  }

  onStatusForceClick(status: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this._reservation.getValue().status = status;
        this.setReservationStatus(token, status);
      }, error => {
        this.authService.signOut();
      }
    );
  }

  onReservationDeleteClick() {
    this.deleteReservationClick.emit();
  }

  onEndContractClick() {
    this.dialogService.openDialog(ReservationDetailEndContractComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success) {
          let reservation: Resource = this._reservation.getValue();
          this._reservation.next(null);
          this.authService.getAuthenticatedUser().subscribe(
            user => {
              this.reservationsService.endContract(reservation, dialogResult.km).subscribe(
                response => {
                  this.readReservation(reservation.key.uri);
                }, error => { console.error(error); }
              );
            }, error => { this.authService.signOut(); }
          );
        }
      }
    );
  }

  readReservation(uri: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.reservationsService.readReservation(token, uri).subscribe(
          response => {
            this._reservation.next(response.data);
          }, error => {
            this.router.navigate([RESERVATIONS_PATH]);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  setReservationStatus(token: string, status: string) {
    this.showMenuSpinner = true;
    this.reservationsService.setStatus(this._reservation.getValue(),status).subscribe(
      response => {
        this.showMenuSpinner = false;
      }, error => {
        this.showMenuSpinner = false;
      }
    );
  }

  getStatusClass() {
    return {
      'irt-reservation-pending-status'    : this._reservation.getValue().status == ReservationState.PENDING,
      'irt-reservation-confirmed-status'  : this._reservation.getValue().status == ReservationState.CONFIRMED,
      'irt-reservation-running-status'    : this._reservation.getValue().status == ReservationState.RUNNING,
      'irt-reservation-closed-status'     : this._reservation.getValue().status == ReservationState.CLOSED,
      'irt-reservation-expired-status'    : this._reservation.getValue().status == ReservationState.EXPIRED
    };
  }
}
