<div id="irt-vehicle-detail-damages-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Danni
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div class="irt-vehicle-detail-damages-content-layout irt-full-width" *ngIf="damages && damages.length>0">
    <div class="irt-half-margin-bottom irt-primary-medium-background irt-padding"
         *ngFor="let damage of damages">
      <div class="irt-spaced-row irt-full-width">
        <p class="irt-no-margin"> {{ damage.date | date: 'short' }} </p>

        <div class="irt-spaced-row">
          <button mat-icon-button (click)="onDamageDeleteClick(damage)"> 
            <mat-icon class="mat-warn"> delete </mat-icon> 
          </button>
        </div>
      </div>

      <p class="irt-no-margin irt-half-margin-top"> {{ damage.description }} </p>
    </div>
  </div>

  <div class="irt-vehicle-detail-damages-content-layout irt-full-width" *ngIf="!damages || damages.length==0">
    <p> Non ci sono danni salvati. Aggiungi un danno premendo il bottone in basso. </p>
  </div>

  <div class="irt-full-width irt-centered-row irt-double-margin-bottom">
    <button class="irt-main-button" mat-button (click)="onDialogConfirmClick()">
      <span> Aggiungi danno </span>
    </button>
  </div>
</div>
