import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PLANNER_PATH } from '../shared/constants';
import { PlannerComponent } from './planner.component';

const routes: Routes = [
  {
    path: PLANNER_PATH,
    component: PlannerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlannerRoutingModule { }
