<div id="irt-reservation-detail-root-layout" class="irt-full-height">
  <app-toolbar  class="irt-toolbar" [currentItem]="navbarItem"></app-toolbar>
  <app-navbar   class="irt-navbar"  [currentItem]="navbarItem"></app-navbar>
  <app-reservation-detail-section 
    class="irt-section" 
    [_reservation]="_reservation"
    (refresh)="onRefreshClick($event)" 
    (deleteReservationClick)="onReservationDeleteClick()"
    *ngIf="_reservation.getValue()"></app-reservation-detail-section>
  <div  
    class="irt-spinner-layout irt-full-width irt-full-height" 
    *ngIf="!_reservation.getValue()">
    <div  
      class="irt-padding irt-border-radius" 
      style="background: #F4F4F4;">
      <mat-spinner  
        [diameter]="30" 
        color="accent"> </mat-spinner>
    </div>
  </div>
</div>
