<div id="irt-auth-forgot-pss-dialog-root-layout">
  <div  id="irt-auth-forgot-pss-dialog-title-layout" 
        class="irt-full-width">
    <h2 class="irt-no-margin irt-primary-medium-color"> Reimposta password </h2> 
  </div>

  <div  id="irt-auth-forgot-pss-dialog-content-layout" 
        class="irt-full-width">
    <p class="irt-no-margin"> 
      Inserisci la codice che hai ricevuto ed imposta una nuova password. 
    </p> 

    <form [formGroup]="formGroup" 
          class="irt-margin-top">
      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> code </mat-label>
        <input  matInput 
                type="text" 
                formControlName="code" 
                required>
      </mat-form-field>

      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> password </mat-label>
        <input  [type]="isPasswordVisible? 'text':'password'" 
                matInput 
                formControlName="password" 
                required>
        <mat-icon class="irt-cursor-pointer" 
                  matSuffix
                  (click)="isPasswordVisible = !isPasswordVisible">
          {{ isPasswordVisible ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> conferma password </mat-label>
        <input  [type]="isPasswordVisible? 'text':'password'" 
                matInput 
                formControlName="passwordConfirm" 
                required>
        <mat-icon class="irt-cursor-pointer" 
                  matSuffix
                  (click)="isPasswordVisible = !isPasswordVisible">
          {{ isPasswordVisible ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
    </form>
  </div>

  <div  id="irt-auth-forgot-pss-dialog-actions-layout" 
        class="irt-full-width">
    <button class="irt-full-width irt-secondary-button irt-half-margin" 
            mat-button 
            (click)="onDialogCloseClick()">
      <span> Chiudi </span> 
    </button>

    <button class="irt-full-width irt-main-button irt-half-margin" 
            mat-button 
            [disabled]="showButtonSpinner" 
            [ngClass]="buttonService.getDisabledClass(showButtonSpinner)"
            (click)="onDialogConfirmClick()">
      <span *ngIf="!showButtonSpinner"> Conferma </span> 
      <div  class="irt-spinner-layout" 
            *ngIf="showButtonSpinner">
        <mat-spinner  [diameter]="20" 
                      color="accent"> </mat-spinner>
      </div>
    </button>
  </div>
</div>
