<div id="irt-reservations-builder-time-root-layout">
    <div  class="irt-full-width irt-spaced-row irt-padding"
          style="padding-bottom: 0;">
      <h3 class="irt-no-margin irt-primary-medium-color"> 
        Completa la prenotazione 
      </h3> 
      <button mat-icon-button (click)="onCancelClick()">
        <mat-icon> clear </mat-icon>
      </button>
    </div>

    <form id="irt-reservations-builder-time-form" 
        class="irt-form irt-hide-scrollbar" 
        [formGroup]="formGroup">

        <p class="irt-full-width"> 
            <b> Informazioni prenotazione </b> 
        </p>
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Luogo di ritiro </mat-label>
            <input  matInput 
                    type="text" 
                    formControlName="start_address" 
                    required>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Luogo di consegna </mat-label>
            <input  matInput 
                    type="text" 
                    formControlName="end_address" 
                    required>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Data inizio </mat-label>
            <input  matInput 
                    type="text" 
                    required
                    formControlName="start_date"
                    [errorStateMatcher]="matcher"
                    [matDatepicker]="startDatePicker">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-quarter-width irt-reservation-builder-time-field">
            <mat-label> Ora inizio </mat-label>
            <mat-select formControlName="start_hour" 
                        required>
                <mat-option *ngFor="let hour of hours" 
                            [value]="hour"> 
                {{ hour }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-quarter-width irt-reservation-builder-time-field">
            <mat-label> Minuti inizio </mat-label>
            <mat-select formControlName="start_minute" 
                        required>
                <mat-option *ngFor="let minute of minutes" 
                            [value]="minute"> 
                {{ minute }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Data fine </mat-label>
            <input  matInput 
                    type="text" 
                    required
                    formControlName="end_date"
                    [errorStateMatcher]="matcher"
                    [matDatepicker]="endDatePicker">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-quarter-width irt-reservation-builder-time-field">
            <mat-label> Ora fine </mat-label>
            <mat-select formControlName="end_hour" 
                        required>
                <mat-option *ngFor="let hour of hours" 
                            [value]="hour"> {{ hour }} </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-quarter-width irt-reservation-builder-time-field">
            <mat-label> Minuti fine </mat-label>
            <mat-select formControlName="end_minute" 
                        required>
                <mat-option *ngFor="let minute of minutes" 
                            [value]="minute"> 
                {{ minute }} 
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Prezzo </mat-label>
            <input  matInput 
                    type="number" 
                    formControlName="price">
        </mat-form-field>
    
        <mat-form-field appearance="outline" 
                        class="irt-half-width irt-reservation-builder-time-field">
            <mat-label> Categoria veicolo </mat-label>
            <input  matInput 
                    type="text" 
                    formControlName="vehicle_category">
        </mat-form-field>

        <div class="irt-full-width irt-centered-row">
            <button mat-button 
                    [ngClass]="getButtonClass()" 
                    [disabled]="!validateForm()" 
                    (click)="onConfirmClick()">
              <h3 class="irt-no-margin"> Conferma </h3>
            </button>
        </div>
    </form>
</div>