import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HOURS_OF_THE_DAY, MINUTES_OF_THE_HOUR} from '../../shared/constants';

@Component({
  selector: 'app-planner-filters',
  templateUrl: './planner-filters.component.html',
  styleUrls: ['./planner-filters.component.scss']
})
export class PlannerFiltersComponent implements OnInit {
  @Input()  formGroup     : FormGroup;
  @Output() searchClick   : EventEmitter<void> = new EventEmitter<void>();
  hours                   : string[] = HOURS_OF_THE_DAY;
  minutes                 : string[] = MINUTES_OF_THE_HOUR;

  constructor() {}

  ngOnInit() {}

  onSearchClick() {
    this.searchClick.emit();
  }
}
