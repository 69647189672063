import {Component, Input} from '@angular/core';
import {NavbarItem, PLANNER_PATH, RESERVATIONS_PATH, USERS_PATH, VEHICLES_PATH} from '../constants';
import {NavbarItemName} from '../translations';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() currentItem: NavbarItem;
  items: NavbarItem[] = [
    {
      name: NavbarItemName.RESERVATIONS,
      icon: 'event',
      text: NavbarItemName.RESERVATIONS,
      path: RESERVATIONS_PATH
    },
    {
      name: NavbarItemName.VEHICLES,
      icon: 'directions_car',
      text: NavbarItemName.VEHICLES,
      path: VEHICLES_PATH
    },
    {
      name: NavbarItemName.USERS,
      icon: 'face',
      text: NavbarItemName.USERS,
      path: USERS_PATH
    },
    {
      name: NavbarItemName.PLANNER,
      icon: 'assignment',
      text: NavbarItemName.PLANNER,
      path: PLANNER_PATH
    }
  ];

  constructor(private router: Router, private authService: AuthService) {}

  onItemClick(item: NavbarItem) {
    this.router.navigate([item.path]);
  }

  onNavbarLogoClick() {
    this.router.navigate([RESERVATIONS_PATH]);
  }

  onLogoutClick() {
    this.authService.signOut();
  }

  getCurrentItemClass(item: NavbarItem) {
    return {
      'irt-navbar-selected-item-layout': this.currentItem && this.currentItem.name == item.name,
      '': !this.currentItem || this.currentItem.name != item.name
    }
  }
}
