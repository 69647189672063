<div id="irt-navbar-wide-root-layout"
     class="irt-full-height irt-full-width irt-accent-background">
  <div id="irt-navbar-wide-logo-layout">
    <img  class="irt-full-width irt-cursor-pointer" 
          (click)="onNavbarLogoClick()"
          src="../../../assets/imgs/irent-logo-light.png">
  </div>

  <div  class="irt-navbar-menu-item-layout irt-cursor-pointer" 
        [ngClass]="getCurrentItemClass(item)"
        (click)="onItemClick(item)" 
        *ngFor="let item of items">
    <mat-icon class="irt-primary-color"> {{ item.icon }} </mat-icon>
    <p class="irt-no-margin irt-primary-color irt-half-margin-left"> 
      {{ item.text | titlecase }} 
    </p>
  </div>

  <div  id="irt-navbar-logout-layout" 
        class="irt-navbar-menu-item-layout irt-cursor-pointer"
        (click)="onLogoutClick()">
    <mat-icon class="irt-primary-color"> logout </mat-icon>
    <p  class="irt-no-margin irt-primary-color irt-half-margin-left" 
        (click)="onLogoutClick()"> 
      Logout 
    </p>
  </div>
</div>
