import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {API} from 'aws-amplify';
import {environment} from '../../environments/environment';
import { Resource } from './core.module';

export const CUSTOMERS_API_NAME     : string = 'customers';
export const CUSTOMERS_API_ENDPOINT : string = environment.API_BASE_URL + '/customers';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  findCustomers(token: string, customerType: string, page?: number, email?: string, firstName?: string, lastName?: string, businessName?: string) {
    const CUSTOMERS_PER_PAGE  : number  = 30;
    let apiName               : string  = CUSTOMERS_API_NAME;
    let path                  : string  = '/';
    let queryStringParams               = {
      'page'          : page ? page : 1,
      'per_page'      : CUSTOMERS_PER_PAGE,
      'customer_type' : customerType
    };
    if (email) {
      queryStringParams['email'] = email;
    }
    if (firstName) {
      queryStringParams['first_name'] = firstName;
    }
    if (lastName) {
      queryStringParams['last_name'] = lastName;
    }
    if (businessName) {
      queryStringParams['business_name'] = businessName;
    }
    let init = {
      response: true,
      'queryStringParameters': queryStringParams
    };
    return from(API.get(apiName, path, init));
  }

  readCustomer(email: string) {
    let apiName : string  = CUSTOMERS_API_NAME;
    let path    : string  = '/email/' + email;
    let init              = { response: true };
    return from(API.get(apiName, path, init));
  }

  updateCustomer(customer: Resource) {
    let apiName : string  = CUSTOMERS_API_NAME;
    let path    : string  = '/' + customer.key.uri;
    let init              = {
      response  : true,
      body      : {content: customer.content}
    };
    return from(API.put(apiName, path, init));
  }

  deleteCustomer(customer: Resource) {
    let apiName : string  = CUSTOMERS_API_NAME;
    let path    : string  = '/' + customer.key.uri;
    let init              = { response: true };
    return from(API.del(apiName, path, init));
  }
}

export class Customer {
    first_name    : string;
    last_name     : string;
    email         : string;
    phone         : { prefix: string; value: string; };
    code          : string;
    address       : string;
    zip_code      : string;
    city          : string;
    province      : string;
    country       : string;
    vat           : string;
    business_name : string;
    dest_code     : string;
    type          : CustomerType;
    license_num   : string;
    license_date  : number;
    license_exp   : number;
    license_place : string;
}

export enum CustomerType {
    PRIVATE = 'private',
    COMPANY = 'company'
}
