import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { ReservationsModule } from './reservations/reservations.module';
import { VehiclesModule } from './vehicles/vehicles.module';
import { ReservationDetailModule } from './reservation-detail/reservation-detail.module';
import { VehicleDetailModule } from './vehicle-detail/vehicle-detail.module';
import { PlannerModule } from './planner/planner.module';
import { MatDialogModule } from "@angular/material/dialog";
import { UsersModule } from './users/users.module';

import { AppComponent } from './app.component';

import { DecimalPipe, registerLocaleData } from "@angular/common";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    UsersModule,
    ReservationsModule,
    VehiclesModule,
    ReservationDetailModule,
    VehicleDetailModule,
    MatDialogModule,
    PlannerModule
  ],
  providers: [
    DecimalPipe,
    { provide: MAT_DATE_LOCALE, useValue: "it-IT" },
    { provide: LOCALE_ID, useValue: "it-IT" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
