<div
  id="irt-reservation-detail-vehicle-root-layout"
  *ngIf="!showButtonSpinner">
  <div *ngIf="!vehicle">
    <button
      class="irt-main-button irt-full-width"
      mat-button
      [disabled]="showButtonSpinner"
      [ngClass]="buttonService.getDisabledClass(showButtonSpinner)"
      (click)="onVehicleBuilderClick()">
      <p class="irt-ellipsized-text irt-no-margin">AGGIUNGI UN VEICOLO</p>
    </button>
  </div>

  <div
    class="irt-double-margin-bottom irt-full-width irt-spaced-row"
    *ngIf="vehicle">
    <h2 class="irt-primary-medium-color irt-no-margin">Veicolo</h2>

    <button
      mat-icon-button
      class="irt-accent-background"
      (click)="onVehicleBuilderClick()">
      <mat-icon class="irt-primary-color"> create </mat-icon>
    </button>
  </div>

  <div
    class="irt-margin-bottom"
    *ngIf="vehicle && vehicle.category">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text">
        Categoria
      </p>
      <p class="irt-no-margin">{{ vehicle.category }}</p>
    </div>
    <hr style="opacity: 0.3" />
  </div>

  <div
    class="irt-margin-bottom"
    *ngIf="vehicle && vehicle.brand">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text">
        Produttore
      </p>
      <p class="irt-no-margin">{{ vehicle.brand }}</p>
    </div>
    <hr style="opacity: 0.3" />
  </div>

  <div
    class="irt-margin-bottom"
    *ngIf="vehicle && vehicle.model">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text">
        Modello
      </p>
      <p class="irt-no-margin">{{ vehicle.model }}</p>
    </div>
    <hr style="opacity: 0.3" />
  </div>

  <div
    class="irt-margin-bottom"
    *ngIf="vehicle && vehicle.plate">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text">Targa</p>
      <p class="irt-no-margin">
        {{ vehicle.plate }}
      </p>
    </div>
    <hr style="opacity: 0.3" />
  </div>

  <!--<div
    class="irt-margin-bottom"
    style="margin-top: -12px"
    *ngIf="vehicle">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text">
        Più informazioni
      </p>
      <button
        mat-icon-button
        (click)="onVehicleClick()">
        <mat-icon> visibility </mat-icon>
      </button>
    </div>
    <hr style="opacity: 0.3; margin-top: -2px" />
  </div>-->
</div>

<div
  class="irt-full-width irt-full-height irt-centered-row"
  *ngIf="showButtonSpinner">
  <mat-spinner
    [diameter]="30"
    color="accent">
  </mat-spinner>
</div>
