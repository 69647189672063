<div id="irt-reservations-filters-dialog-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Filtri sulle prenotazioni 
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-reservations-filters-dialog-content-layout" class="irt-full-width">
    <p> Scegli le date di inzio o fine prenotazione </p>

    <form [formGroup]="formGroup" 
          class="irt-spaced-row irt-wrapped-row">
      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> Targa </mat-label>
        <input  matInput 
                type="text" 
                formControlName="plate">
      </mat-form-field>

      <mat-form-field appearance="outline" 
                      class="irt-half-width">
        <mat-label> Data inizio </mat-label>
        <input  matInput 
                type="text" 
                formControlName="start_date" 
                [matDatepicker]="startDatePicker">
        <mat-datepicker-toggle  matSuffix 
                                [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" 
                      class="irt-half-width irt-reservation-builder-basics-field">
        <mat-label> Data fine </mat-label>
        <input  matInput 
                type="text" 
                formControlName="end_date" 
                [matDatepicker]="endDatePicker">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </form>

    <p  class="irt-no-margin irt-cursor-pointer irt-primary-medium-color irt-right-text" 
        (click)="onFiltersRemoveClick()"> 
        <u> Cancella filtri  </u>
    </p>
  </div>

  <div  class="irt-full-width irt-centered-row irt-double-margin-bottom">
    <button class="irt-main-button" 
            mat-button 
            (click)="onDialogConfirmClick()">
      <span> Conferma </span> 
    </button>
  </div>
</div>
