import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { ReservationState } from 'src/app/shared/constants';

@Component({
  selector: 'app-reservation-detail-status',
  templateUrl: './reservation-detail-status.component.html',
  styleUrls: ['./reservation-detail-status.component.scss']
})
export class ReservationDetailStatusComponent implements OnInit {
  previousStatus  : ReservationState;
  formGroup       : FormGroup;
  states          : ReservationState[] = [
    ReservationState.PENDING, ReservationState.CONFIRMED,
    ReservationState.RUNNING, ReservationState.CLOSED,
    ReservationState.EXPIRED
  ];

  constructor(private formService : FormService,
              private dialogRef   : MatDialogRef<ReservationDetailStatusComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.formGroup      = formService.reservationDetailStatusFormGroup;
    this.previousStatus = data.status;
  }

  ngOnInit(): void {
    this.formGroup.get('status').setValue(this.previousStatus);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      this.dialogRef.close({ success: true, status: this.formGroup.get('status').value });
    }
  }
}
