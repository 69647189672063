import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {from} from 'rxjs';
import {Auth} from 'aws-amplify';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;

  constructor(private router: Router) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  public signUp(email: string, password: string): Observable<any> {
    return from(Auth.signUp(email, password));
  }

  public signIn(email, password): Observable<any>    {
    return from(Auth.signIn(email, password)).pipe(
      tap(() => this.loggedIn.next(true))
    );
  }

  public getAuthenticatedUser(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }

  public isAuthenticated(): Observable<boolean> {
    return from(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  public signOut() {
    from(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          this.router.navigate(['/auth']);
        },
        error => console.log(error)
      );
  }

  public forgotPassword(email: string) {
    return from(Auth.forgotPassword(email));
  }

  public forgotPasswordSubmit(email: string, code: string, newPassword: string) {
    return from(Auth.forgotPasswordSubmit(email, code, newPassword));
  }
}
