import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Deductible, Service, ServiceTitle } from 'src/app/core/reservations.service';

@Component({
  selector: 'app-reservation-detail-start-contract',
  templateUrl: './reservation-detail-start-contract.component.html',
  styleUrls: ['./reservation-detail-start-contract.component.scss']
})
export class ReservationDetailStartContractComponent implements OnInit {
  formGroup           : FormGroup;
  serviceFormGroup    : FormGroup;
  servicesFormGroup   : FormGroup;
  deductibleFormGroup : FormGroup;
  services          : Service[]; 
  selectedServices  : ServiceTitle[];
  customServices    : Service[];
  customeDeductibles: Deductible[];
  send_contract     : boolean = true;
  serviceTitles     : ServiceTitle[] = [
    ServiceTitle.FULL_KASKO_TITLE, 
    ServiceTitle.UNLIMITED_KM_TITLE,
    ServiceTitle.LIMITED_KM_TITLE,
    ServiceTitle.DAMAGE_DEDUCTIBLE_TITLE,
    ServiceTitle.THEFT_DEDUCTIBLE_TITLE
  ]; 

  constructor(
    private formService : FormService,
    private dialogRef   : MatDialogRef<ReservationDetailStartContractComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.formGroup            = formService.reservationVehicleKmFormGroup;
    this.serviceFormGroup     = formService.reservationServiceFormGroup;
    this.servicesFormGroup    = formService.reservationServicesFormGroup;
    this.deductibleFormGroup  = formService.reservationDeductibleFormGroup;
  }

  ngOnInit(): void {
    this.selectedServices   = [];
    this.customServices     = [];
    this.customeDeductibles = [];
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onServiceAddClick(event, service_title: ServiceTitle) {
    if (event.checked) {
      this.selectedServices.push(service_title);
    } else {
      const index = this.selectedServices.indexOf(service_title);
      if (index != -1) {
        this.selectedServices.splice(index, 1);
      }
    }
  }

  onCustomServiceAddClick() {
    const service = new Service();
    service.title = this.serviceFormGroup.get('name').value;
    service.price = this.serviceFormGroup.get('price').value * 100;
    this.customServices.push(service);
  }

  onCustomDeductibleAddClick() {
    const deductible = new Deductible();
    deductible.title = this.deductibleFormGroup.get('name').value;
    deductible.price = this.deductibleFormGroup.get('price').value * 100;
    this.customeDeductibles.push(deductible);
  }

  onConfirmClick() {
    const km: number = this.formGroup.get('km').value;
    if (this.formService.validateForm(this.formGroup) && km) {
      const service: Service[] = [];
      this.selectedServices.map(s => {
        service.push(this.buildService(s));
      });

      this.dialogRef.close({ 
        success       : true, 
        km            : km,
        services      : service.concat(this.customServices),
        deductibles   : this.customeDeductibles,
        send_contract : this.send_contract 
      });
    }
  }

  buildService(service_title: string): Service {
    let title: string, price: number;
    switch (service_title) {
      case this.serviceTitles[0]:
        title = 'Polizza Full Kasko';
        price = this.servicesFormGroup.get('full_kasko').value;
        break;
      case this.serviceTitles[1]:
        title = 'Km illimitati';
        price = this.servicesFormGroup.get('unlimited_km').value;
        break;
      case this.serviceTitles[2]:
        const quantity = this.servicesFormGroup.get('limited_km_quantity').value;
        if (quantity) {
          title = 'Km limitati (' + quantity + 'km)';
        } else {
          title = 'Km limitati';
        }
        price = this.servicesFormGroup.get('limited_km').value;
        break;
      case this.serviceTitles[3]:
        title = 'Franchigia danni';
        price = this.servicesFormGroup.get('damage_deductible').value;
        break;
      case this.serviceTitles[4]:
        title = 'Franchigia furto';
        price = this.servicesFormGroup.get('theft_deductible').value;
        break;
      default:
        title = 'Extra';
        price = 0;
        break;
    }

    const service = new Service();
    service.title = title;
    service.price = price * 100; 
    return service;
  }
}
