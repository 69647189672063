import {NavbarItemName} from './translations';

/** PATHS **/
export const AUTH_PATH                : string = 'auth';
export const RESERVATIONS_PATH        : string = 'reservations';
export const VEHICLES_PATH            : string = 'vehicles';
export const RESERVATION_DETAIL_PATH  : string = 'reservation';
export const VEHICLE_DETAIL_PATH      : string = 'vehicle';
export const PLANNER_PATH             : string = 'planner';
export const USERS_PATH               : string = 'users';

/** DIALOGS **/
export const ERROR_DIALOG_TITLE: string = 'Errore';

/** NAVBAR ITEMS **/
export class NavbarItem {
  name: NavbarItemName;
  icon: string;
  text: string;
  path: string;
}

/** RESERVATION STATES **/
export enum ReservationState {
  PENDING     = 'pending',
  CONFIRMED   = 'confirmed',
  RUNNING     = 'running',
  CLOSED      = 'closed',
  EXPIRED     = 'expired'
}

/** VEHICLE STATES **/
export enum VehicleState {
  FREE      = 'free',
  SCHEDULED = 'scheduled',
  BUSY      = 'busy',
  HIDDEN    = 'hidden'
}

/** STYLES VARIABLES **/
export const XS_CLASS_NAME      : string = 'xs';
export const SM_CLASS_NAME      : string = 'sm';
export const MD_CLASS_NAME      : string = 'md';
export const LG_CLASS_NAME      : string = 'lg';
export const XL_CLASS_NAME      : string = 'xl';
export const XXL_CLASS_NAME     : string = 'xxl';
export const STYLES_VARIABLES   : Map<string, number> = new Map([
  [XS_CLASS_NAME,   0],
  [SM_CLASS_NAME,   576],
  [MD_CLASS_NAME,   768],
  [LG_CLASS_NAME,   992],
  [XL_CLASS_NAME,   1200],
  [XXL_CLASS_NAME,  1640]
]);

/** HOURS **/
export const HOURS_OF_THE_DAY: string[] = [
  '00','01','02','03','04','05','06','07',
  '08','09','10','11','12','13','14','15',
  '16','17','18','19','20','21','22','23'
];

export const MINUTES_OF_THE_HOUR: string[] = [
  '00','05','10','15','20','25','30','35','40','45','50','55'
];

/** API ERROR CODES **/
export const RESOURCE_NOT_FOUND_CODE: string = 'ResourceNotFound';

/** ADDRESSES **/
export const HEADQUARTER_ADDRESS_VALUE  : string = 'siracusa-sede';
export const STATION_ADDRESS_VALUE      : string = 'siracusa-stazione';
export const AIRPORT_ADDRESS_VALUE      : string = 'catania';
