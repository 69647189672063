<div id="irt-auth-forgot-pss-dialog-root-layout">
  <div  id="irt-auth-forgot-pss-dialog-title-layout" 
        class="irt-full-width">
    <h2 class="irt-no-margin irt-primary-medium-color"> Password dimenticata </h2> 
  </div>

  <div  id="irt-auth-forgot-pss-dialog-content-layout" 
        class="irt-full-width">
    <p class="irt-no-margin"> 
      Inserisci la mail con cui sei registrato, ti invieremo una mail 
      con il codice da inserire per reimpostare la tua password. 
    </p> 

    <form [formGroup]="formGroup" 
          class="irt-margin-top">
      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> email </mat-label>
        <input  matInput 
                type="email" 
                formControlName="email" 
                required>
      </mat-form-field>
    </form>
  </div>

  <div  id="irt-auth-forgot-pss-dialog-actions-layout" 
        class="irt-full-width">
    <button class="irt-full-width irt-secondary-button irt-half-margin" 
            mat-button 
            (click)="onDialogCloseClick()">
      <span> Chiudi </span> 
    </button>

    <button class="irt-full-width irt-main-button irt-half-margin" 
            mat-button 
            [disabled]="showButtonSpinner" 
            [ngClass]="buttonService.getDisabledClass(showButtonSpinner)"
            (click)="onDialogConfirmClick()">
      <span *ngIf="!showButtonSpinner"> Conferma </span> 
      <div  class="irt-spinner-layout" 
            *ngIf="showButtonSpinner">
        <mat-spinner  [diameter]="20" 
                      color="accent"> </mat-spinner>
      </div>
    </button>
  </div>
</div>
