/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { ReservationsRoutingModule }        from './reservations-routing.module';
import { SharedModule }                     from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatNativeDateModule }      from '@angular/material/core';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatCheckboxModule }        from '@angular/material/checkbox';

/** Components **/
import { ReservationsComponent }                from './reservations.component';
import { ReservationsSectionComponent }         from './reservations-section/reservations-section.component';
import { ReservationsTableComponent }           from './reservations-table/reservations-table.component';
import { ReservationsBuilderBasicsComponent }   from './reservations-builder-basics/reservations-builder-basics.component';
import { ReservationsBuilderVehicleComponent }  from './reservations-builder-vehicle/reservations-builder-vehicle.component';
import { ReservationsFiltersComponent }         from './reservations-filters/reservations-filters.component';
import { ReservationsBuilderTimeComponent }     from './reservations-builder-time/reservations-builder-time.component';

@NgModule({
  declarations: [
    ReservationsComponent,
    ReservationsSectionComponent,
    ReservationsTableComponent,
    ReservationsBuilderBasicsComponent,
    ReservationsBuilderVehicleComponent,
    ReservationsFiltersComponent,
    ReservationsBuilderTimeComponent
  ],
  imports: [
    CommonModule,
    ReservationsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCheckboxModule
  ],
  entryComponents: [
    ReservationsBuilderBasicsComponent,
    ReservationsBuilderTimeComponent,
    ReservationsBuilderVehicleComponent,
    ReservationsFiltersComponent
  ],
  exports: [
    ReservationsBuilderVehicleComponent, 
    ReservationsBuilderBasicsComponent, 
    ReservationsBuilderTimeComponent
  ]
})
export class ReservationsModule { }
