import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-reservation-detail-end-contract',
  templateUrl: './reservation-detail-end-contract.component.html',
  styleUrls: ['./reservation-detail-end-contract.component.scss']
})
export class ReservationDetailEndContractComponent implements OnInit {
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<ReservationDetailEndContractComponent>,
              @Inject(MAT_DIALOG_DATA) data, private formService: FormService) {
    this.formGroup = formService.reservationVehicleKmFormGroup;
  }

  ngOnInit() {}

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let km: number = this.formGroup.get('km').value;
      this.dialogRef.close({success: true, km: km});
    }
  }
}
