import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Reservation } from './reservations.service';
import { Vehicle } from './vehicles.service';
import { Customer } from './customers.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CoreModule {}

export class Resource {
  key         : Key;
  hid         : string;
  status      : string;
  content     : Reservation | Vehicle | Customer;
  links       : Link[];
  created_at  : number;
  updated_at  : number;
}

export class Key {
  uri   : string;
  bid   : string;
  type  : string;
}

export class Link {
  resource: Resource;
}
