/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { SharedModule }                     from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatNativeDateModule }      from '@angular/material/core';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatCheckboxModule }        from '@angular/material/checkbox';

/** Components **/
import { UserDetailsDialogComponent } from './user-details-dialog/user-details-dialog.component';
import { UserDetailsUpdateDialogComponent } from './user-details-update-dialog/user-details-update-dialog.component';

@NgModule({
  declarations: [
    UserDetailsDialogComponent, 
    UserDetailsUpdateDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatCheckboxModule,
    SharedModule,
    ReactiveFormsModule, 
    FormsModule
  ],
  entryComponents: [
    UserDetailsDialogComponent,
    UserDetailsUpdateDialogComponent
  ]
})
export class UserDetailsModule { }
