import {Component} from '@angular/core';
import {NavbarItem} from '../shared/constants';
import {NavbarItemName} from '../shared/translations';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent {
  navbarItem: NavbarItem;

  constructor() {
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.PLANNER;
  }
}
