<div id="irt-vehicle-detail-qr-generator-root-layout">
  <div id="irt-vehicle-detail-qr-generator-title-layout" class="irt-full-width">
    <p class="irt-no-margin irt-primary-color"> QR code generator </p> </div>

  <div class="irt-vehicle-detail-qr-generator-content-layout irt-full-width irt-centered-column">
    <h3 class="irt-centered-text"> QR code generato per la targa {{ value }} </h3>
  </div>

  <div id="irt-vehicle-detail-qr-generator-actions-layout" class="irt-full-width">
    <button class="irt-secondary-button" mat-button
            (click)="onDialogCloseClick()" style="min-width: 30%;"> <span> Chiudi </span> </button>
  </div>
</div>
