import {Component, Inject, OnInit} from '@angular/core';
import {Vehicle, VehicleRevision, VehiclesService} from '../../core/vehicles.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth.service';
import { Resource } from 'src/app/core/core.module';

@Component({
  selector: 'app-vehicle-detail-revisions',
  templateUrl: './vehicle-detail-revisions.component.html',
  styleUrls: ['./vehicle-detail-revisions.component.scss']
})
export class VehicleDetailRevisionsComponent implements OnInit {
  vehicle           : Resource;
  revisions         : VehicleRevision[];
  showButtonSpinner : boolean = false;

  constructor(private dialogRef       : MatDialogRef<VehicleDetailRevisionsComponent>,
              private vehiclesService : VehiclesService, 
              private authService     : AuthService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.revisions  = data.revisions;
    this.vehicle    = data.vehicle;
  }

  ngOnInit() {}

  onRevisionDeleteClick(revision: VehicleRevision) {
    const index = this.revisions.indexOf(revision);
    if (index != -1) { 
      this.authService.getAuthenticatedUser().subscribe(
        user => {
          const token : string = user.signInUserSession.idToken.jwtToken;
          this.revisions.splice(index, 1);
          (this.vehicle.content as Vehicle).revisions = this.revisions;
          this.updateVehicle(token);
        }, error => {
          this.authService.signOut();
        }
      );
    }
  }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    this.dialogRef.close({add: true});
  }

  updateVehicle(token: string) {
    this.showButtonSpinner = true;
    this.vehiclesService.updateVehicle(token, this.vehicle).subscribe(
      response => {
        this.showButtonSpinner = false;
      }, error => {
        this.showButtonSpinner = false;
      }
    );
  }
}
