<header>
  <mat-toolbar id="irt-toolbar">
    <mat-toolbar-row id="irt-toolbar-row">
      <img  class="irt-toolbar-logo" 
            src="../../../assets/imgs/irent-logo-dark.png">

      <button id="irt-toolbar-menu-button" 
              mat-icon-button
              (click)="onFullScreenMenuToggleClick()"> 
        <mat-icon> sort </mat-icon> 
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<div  id="irt-toolbar-full-screen-menu-layout" 
      *ngIf="showFullScreenMenu" 
      [@slideInOut]>
  <div id="irt-toolbar-full-screen-menu-header-layout">
    <img  class="irt-toolbar-logo" 
          src="../../../assets/imgs/irent-logo-light.png">
    <button mat-icon-button 
            (click)="onFullScreenMenuToggleClick()">
      <mat-icon> clear </mat-icon> 
    </button>
  </div>

  <div  *ngFor="let item of items" 
        (click)="onItemClick(item)"
        class="irt-margin-bottom irt-double-margin-top irt-cursor-pointer" 
        style="padding: 0 24px; box-sizing: border-box">
    <p  class="irt-primary-color irt-no-margin" 
        [ngClass]="getItemTextClass(item)">
      {{ item.text | titlecase }} 
    </p>
  </div>
</div>
