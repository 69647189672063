import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ButtonService {
  getDisabledClass(flag: boolean) {
    return {
      'irt-main-disabled-button'  : flag,
      'irt-main-button'           : !flag
    }
  }
}
