import {Component, OnInit} from '@angular/core';
import {Resource} from '../../core/core.module';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {Vehicle, VehiclesService} from '../../core/vehicles.service';
import {VehiclesBuilderImageComponent} from '../vehicles-builder-image/vehicles-builder-image.component';
import {VehiclesBuilderBasicsComponent} from '../vehicles-builder-basics/vehicles-builder-basics.component';
import {RESOURCE_NOT_FOUND_CODE} from '../../shared/constants';
import {AuthService} from '../../core/auth.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-vehicles-section',
  templateUrl: './vehicles-section.component.html',
  styleUrls: ['./vehicles-section.component.scss']
})
export class VehiclesSectionComponent implements OnInit {
  vehicles                : Resource[];
  _vehicles               : BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>(null);
  nextPage                : number = 1;
  currentPage             : number = 1;
  totalPages              : number = 1000;
  showLoadButtonSpinner   : boolean = false;

  constructor(private vehiclesService: VehiclesService, private authService: AuthService,
              private dialogService: DialogService, private dialog: MatDialog) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.nextPage = 1;
    this.readVehicles();
  }

  onAddVehicleClick() {
    this.dialogService.openDialog(VehiclesBuilderBasicsComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success && dialogResult.vehicle) {
          let basicsVehicle = dialogResult.vehicle;
          this.dialogService.openDialog(VehiclesBuilderImageComponent, {vehicle: basicsVehicle}).afterClosed().subscribe(
            dialogResult => {
              if (dialogResult && dialogResult.success && dialogResult.vehicle) {
                this.createVehicle(dialogResult.vehicle);
              } else {
                this.createVehicle(basicsVehicle);
              }
            }
          );
        }
      }
    );
  }

  onLoadClick() {
    this.nextPage++;
    this.readVehicles();
  }

  onRefreshClick() {
    this.nextPage = 1;
    this.vehicles = null;
    this._vehicles.next(this.vehicles);
    this.readVehicles();
  }

  onVehiclesReloaded(searchText: string) {
    this.nextPage = 1;
    this.vehicles = null;
    this._vehicles.next(this.vehicles);
    this.readVehicles(searchText);
  }

  createVehicle(vehicle: Vehicle) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.vehicles = null;
        this._vehicles.next(this.vehicles);
        this.vehiclesService.createVehicle(token, vehicle).subscribe(
          response => {
            this.readVehicles();
          }, error => {
            this.readVehicles();
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  readVehicles(searchText?: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.showLoadButtonSpinner = true;
        this.vehiclesService.findVehicles(token,this.nextPage,searchText).subscribe(
          response => {
            if (this.nextPage == 1) {
              this.vehicles = response.data.data;
              this._vehicles.next(this.vehicles);
            } else {
              this.vehicles = this.vehicles.concat(response.data.data);
              this._vehicles.next(this.vehicles);
            }
            this.currentPage = response.data.page;
            this.totalPages = response.data.total_pages;
            this.showLoadButtonSpinner = false;
          }, error => {
            if (error.response.data.error.code == RESOURCE_NOT_FOUND_CODE) {
              this.vehicles = [];
              this._vehicles.next(this.vehicles);
            }
            this.showLoadButtonSpinner = false;
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }
}
