import {Component} from '@angular/core';
import {NavbarItem} from '../shared/constants';
import {NavbarItemName} from '../shared/translations';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent {
  navbarItem: NavbarItem;

  constructor() {
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.VEHICLES;
  }
}
