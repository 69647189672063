<div id="irt-reservations-builder-basics-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Aggiungi una prenotazione 
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <form id="irt-reservations-builder-basics-form" 
        class="irt-form" 
        [formGroup]="formGroup">
		<h3 
			style="margin-top: 0;"
			class="irt-full-width"> 
      <b> Tipologia di cliente </b>
		</h3>
		<mat-form-field appearance="outline" 
                class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Tipologia </mat-label>
      <mat-select formControlName="type" 
                  (selectionChange)="onTypeChange()">
        <mat-option [value]="privateView"> Privato </mat-option>
        <mat-option [value]="businessView"> Azienda </mat-option>
      </mat-select>
    </mat-form-field>

		<h3 class="irt-full-width" 
        *ngIf="currentView == businessView"> 
      <b> Informazioni azienda </b>
		</h3>

    <mat-form-field appearance="outline" 
                    class="irt-full-width irt-reservation-builder-basics-field"
                    *ngIf="currentView == businessView">
      <mat-label> Ragione sociale </mat-label>
      <input  matInput 
              type="text" 
              (change)="onBusinessNameChange()"
              formControlName="business_name">
      <mat-spinner  
        matSuffix 
        color="accent" 
        diameter="20" 
        *ngIf="isBusinessLoading"></mat-spinner>
    </mat-form-field>

		<mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field"
                    *ngIf="currentView == businessView">
      <mat-label> P.IVA </mat-label>
      <input  matInput 
              type="text" 
              formControlName="vat">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field"
                    *ngIf="currentView == businessView">
      <mat-label> Codice destinatario </mat-label>
      <input  matInput 
              type="text" 
              formControlName="dest_code">
    </mat-form-field>

    <h3 class="irt-full-width"> 
      <b> Informazioni conducente </b> 
		</h3>
    <mat-form-field appearance="outline" 
                    class="irt-full-width irt-reservation-builder-basics-field">
      <mat-label> Email </mat-label>
      <input  matInput 
              type="text" 
              formControlName="email" 
              (change)="onEmailChange()">
      <mat-spinner  matSuffix 
                    color="accent" 
                    diameter="20" 
                    *ngIf="isEmailLoading"></mat-spinner>
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Nome </mat-label>
      <input  matInput 
              type="text" 
              formControlName="first_name">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Cognome </mat-label>
      <input  matInput 
              type="text" 
              formControlName="last_name">
    </mat-form-field>

    <mat-form-field   appearance="outline" 
                      class="irt-full-width irt-reservation-builder-basics-field">
      <mat-label> Codice fiscale </mat-label>
      <input  matInput 
              type="text" 
              formControlName="code">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-third-width irt-reservation-builder-basics-field">
      <mat-label> Prefisso </mat-label>
      <mat-icon matPrefix 
                class="irt-primary-medium-color"> add </mat-icon>
      <input  matInput 
              type="text" 
              formControlName="phone_prefix">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-three-third-width irt-reservation-builder-basics-field">
      <mat-label> Telefono </mat-label>
      <input  matInput 
              type="text" 
              formControlName="phone">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-full-width irt-reservation-builder-basics-field">
      <mat-label> Indirizzo </mat-label>
      <input  matInput 
              type="text" 
              formControlName="address">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Città di residenza </mat-label>
      <input  matInput 
              type="text" 
              formControlName="city">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Stato di residenza </mat-label>
      <input  matInput 
              type="text" 
              formControlName="country">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Provincia </mat-label>
      <input  matInput 
              type="text" 
              formControlName="province">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Codice postale </mat-label>
      <input  matInput 
              type="text" 
              formControlName="zip_code">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Numero patente </mat-label>
      <input  matInput 
              type="text" 
              formControlName="license_num">
    </mat-form-field>

    <mat-form-field appearance="outline" 
                    class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Luogo emissione patente </mat-label>
      <input  matInput 
              type="text" 
              formControlName="license_place">
    </mat-form-field>
  
    <mat-form-field class="irt-half-width irt-reservation-builder-basics-field"
                    appearance="outline">
      <mat-label> Data emissione patente </mat-label>
      <input matInput 
              type="text"
             formControlName="license_date"
             [matDatepicker]="licenseDatePicker">
      <mat-datepicker-toggle matSuffix [for]="licenseDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #licenseDatePicker></mat-datepicker>
    </mat-form-field>
  
    <mat-form-field class="irt-half-width irt-reservation-builder-basics-field" 
                    appearance="outline">
      <mat-label> Data scadenza patente </mat-label>
      <input  matInput 
              type="text"
              formControlName="license_exp"
              [matDatepicker]="licenseExpDatePicker">
      <mat-datepicker-toggle matSuffix [for]="licenseExpDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #licenseExpDatePicker></mat-datepicker>
    </mat-form-field>

    <div  class="irt-centered-row irt-margin-bottom" 
          style="justify-content: flex-start">
      <mat-checkbox class="irt-checkbox-layout" 
                    formControlName="save"></mat-checkbox>
      <h3 class="irt-no-margin irt-half-margin-left irt-primary-medium-color"> Salvare il cliente? </h3>
    </div>

    <div class="irt-full-width irt-centered-row">
      <button mat-button 
              [ngClass]="getButtonClass()" 
              [disabled]="!validateForm()" 
              (click)="onConfirmClick()">
        <h3 class="irt-no-margin"> Conferma </h3>
      </button>
    </div>
  </form>
</div>
