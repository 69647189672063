/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { ReservationDetailRoutingModule }   from './reservation-detail-routing.module';
import { SharedModule }                     from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatNativeDateModule }      from '@angular/material/core';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatCheckboxModule }        from '@angular/material/checkbox';
import { MatMenuModule }            from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/** Components **/
import { ReservationDetailComponent }               from './reservation-detail.component';
import { ReservationDetailSectionComponent }        from './reservation-detail-section/reservation-detail-section.component';
import { ReservationDetailUserComponent }           from './reservation-detail-user/reservation-detail-user.component';
import { ReservationDetailGeneralComponent }        from './reservation-detail-general/reservation-detail-general.component';
import { ReservationDetailVehicleComponent }        from './reservation-detail-vehicle/reservation-detail-vehicle.component';
import { ReservationDetailEndContractComponent }    from './reservation-detail-end-contract/reservation-detail-end-contract.component';
import { ReservationDetailStartContractComponent }  from './reservation-detail-start-contract/reservation-detail-start-contract.component';
import { ReservationDetailStatusComponent }         from './reservation-detail-status/reservation-detail-status.component';
import { ReservationDetailStartContractNotesComponent } from './reservation-detail-start-contract-notes/reservation-detail-start-contract-notes.component';

@NgModule({
  declarations: [
    ReservationDetailComponent,
    ReservationDetailSectionComponent,
    ReservationDetailUserComponent,
    ReservationDetailGeneralComponent,
    ReservationDetailVehicleComponent,
    ReservationDetailEndContractComponent,
    ReservationDetailStartContractComponent,
    ReservationDetailStatusComponent,
    ReservationDetailStartContractNotesComponent
  ],
  imports: [
    CommonModule,
    ReservationDetailRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSlideToggleModule
  ],
  entryComponents: [
    ReservationDetailStartContractComponent,
    ReservationDetailStatusComponent,
    ReservationDetailEndContractComponent,
    ReservationDetailStartContractNotesComponent
  ]
})
export class ReservationDetailModule { }
