import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../core/dialog.service';
import {ReservationsBuilderBasicsComponent} from '../reservations-builder-basics/reservations-builder-basics.component';
import {MatDialog} from '@angular/material/dialog';
import {Reservation, ReservationsService} from '../../core/reservations.service';
import {Resource} from '../../core/core.module';
import {AuthService} from '../../core/auth.service';
import {RESOURCE_NOT_FOUND_CODE} from '../../shared/constants';
import {BehaviorSubject} from 'rxjs';
import {ReservationsFiltersComponent} from '../reservations-filters/reservations-filters.component';
import { ButtonService } from 'src/app/core/button.service';
import { ReservationsBuilderTimeComponent } from '../reservations-builder-time/reservations-builder-time.component';

@Component({
  selector: 'app-reservations-section',
  templateUrl: './reservations-section.component.html',
  styleUrls: ['./reservations-section.component.scss']
})
export class ReservationsSectionComponent implements OnInit {
  reservations          : Resource[];
  _reservations         : BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>(null);
  nextPage              : number = 1;
  currentPage           : number = 1;
  totalPages            : number = 1000;
  showLoadButtonSpinner : boolean = false;

  constructor(private reservationsService : ReservationsService, 
              private authService         : AuthService,
              private dialogService       : DialogService, 
              private dialog              : MatDialog,
              public  buttonService       : ButtonService) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.nextPage = 1;
    this.readReservations();
  }

  onAddReservationClick() {
    console.log('Opening dialog');
    this.dialogService.openDialog(ReservationsBuilderBasicsComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success && dialogResult.reservation) {
          const save        : boolean       = dialogResult.save;
          let reservation   : Reservation   = dialogResult.reservation;
          this.dialogService.openDialog(ReservationsBuilderTimeComponent, { reservation: reservation }).afterClosed().subscribe(
            dialogResult => {
              if (dialogResult && dialogResult.success && dialogResult.reservation) {
                this.createReservation(dialogResult.reservation, save);
              }
            }
          );
        }
      }
    );
  }

  onLoadClick() {
    this.nextPage++;
    this.readReservations();
  }

  onRefreshClick() {
    this.nextPage = 1;
    this._reservations.next(null);
    this.readReservations();
  }

  onReservationsReloaded(searchText: string) {
    this.nextPage = 1;
    this._reservations.next(null);
    this.readReservations(searchText);
  }

  onFiltersClick() {
    this.dialogService.openDialog(ReservationsFiltersComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && (dialogResult.start_date || dialogResult.end_date || dialogResult.plate)) {
          this.nextPage = 1;
          this._reservations.next(null);
          this.readReservations(null, dialogResult.start_date, dialogResult.end_date, dialogResult.plate);
        }
      }
    );
  }

  createReservation(reservation: Reservation, save: boolean) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this._reservations.next(null);
        this.reservationsService.createReservation(token, reservation, save).subscribe(
          response => {
            this.readReservations();
          }, error => {
            this.readReservations();
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  readReservations(searchText?: string, startDate?: number, endDate?: number, plate?: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string           = user.signInUserSession.idToken.jwtToken;
        this.showLoadButtonSpinner  = true;
        this.reservationsService.findReservations(token,this.nextPage,searchText, startDate, endDate, plate).subscribe(
          response => {
            let reservations: Resource[];
            if (this.nextPage == 1) {
              reservations = response.data.data;
            } else {
              reservations = this._reservations.getValue().concat(response.data.data);
            }
            this._reservations.next(reservations);
            this.currentPage            = response.data.page;
            this.totalPages             = response.data.total_pages;
            this.showLoadButtonSpinner  = false;
          }, error => {
            if (error.response.data.error.code == RESOURCE_NOT_FOUND_CODE) {
              this._reservations.next([]);
            }
            this.showLoadButtonSpinner = false;
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }
}
