<div id="irt-vehicles-section-root-layout" class="irt-full-height">
  <div class="irt-full-width irt-spaced-row">
    <h1 class="irt-no-margin"> Veicoli </h1>
    <button mat-fab class="irt-centered-row" (click)="onAddVehicleClick()" matTooltip="Aggiungi veicolo">
      <mat-icon> add </mat-icon>
    </button>
  </div>

  <app-vehicles-table [_vehicles]="_vehicles" (refreshClick)="onRefreshClick()"
                      (reloadVehicles)="onVehiclesReloaded($event)"
                      *ngIf="vehicles && vehicles.length>0"></app-vehicles-table>

  <div class="irt-full-width irt-centered-row irt-margin-top"
       *ngIf="vehicles && vehicles.length>0 && currentPage < totalPages">
    <button mat-button class="irt-main-button" (click)="onLoadClick()">
      <span *ngIf="!showLoadButtonSpinner"> Carica altro </span>
      <div class="irt-spinner-layout" *ngIf="showLoadButtonSpinner">
        <mat-spinner [diameter]="20" color="accent"> </mat-spinner>
      </div>
    </button>
  </div>

  <div  class="irt-centered-column"
        style="height: calc(100% - 104px)"  
        *ngIf="vehicles && vehicles.length<=0">
    <h3> Non ci sono veicoli salvati </h3>
    <div class="irt-centered-row irt-wrapped-row">
      <button mat-button class="irt-secondary-button"
              (click)="onRefreshClick()" style="margin: 12px">
        <span> Ricarica veicoli </span>
      </button>
      <button mat-button class="irt-main-button"
              (click)="onAddVehicleClick()" style="margin: 12px">
        <span> Aggiungi un veicolo </span>
      </button>
    </div>
  </div>

  <div  class="irt-spinner-layout irt-full-width irt-centered-row"
        style="height: calc(100% - 104px)" 
        *ngIf="!vehicles">
    <div  class="irt-padding irt-border-radius" 
          style="background: #F4F4F4;">
      <mat-spinner  [diameter]="30" 
                    color="accent"> </mat-spinner>
    </div>
  </div>
</div>
