import {Component, Inject, OnInit} from '@angular/core';
import {Vehicle, VehicleDamage, VehiclesService} from '../../core/vehicles.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth.service';
import { Resource } from 'src/app/core/core.module';

@Component({
  selector: 'app-vehicle-detail-damages',
  templateUrl: './vehicle-detail-damages.component.html',
  styleUrls: ['./vehicle-detail-damages.component.scss']
})
export class VehicleDetailDamagesComponent implements OnInit {
  vehicle: Resource;
  damages: VehicleDamage[];
  showButtonSpinner: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<VehicleDetailDamagesComponent>,
    private authService: AuthService,
    private vehiclesService : VehiclesService,
    @Inject(MAT_DIALOG_DATA) data) {
    this.damages = data.damages;
    this.vehicle = data.vehicle;
  }

  ngOnInit() {}

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    this.dialogRef.close({add: true});
  }

  onDamageDeleteClick(damage: VehicleDamage) {
    const index = this.damages.indexOf(damage);
    if (index != -1) { 
      this.authService.getAuthenticatedUser().subscribe(
        user => {
          const token : string = user.signInUserSession.idToken.jwtToken;
          this.damages.splice(index, 1);
          (this.vehicle.content as Vehicle).damages = this.damages;
          this.updateVehicle(token);
        }, error => {
          this.authService.signOut();
        }
      );
    }
  }

  updateVehicle(token: string) {
    this.showButtonSpinner = true;
    this.vehiclesService.updateVehicle(token, this.vehicle).subscribe(
      response => {
        this.showButtonSpinner = false;
      }, error => {
        this.showButtonSpinner = false;
      }
    );
  }
}
