<div id="irt-vehicle-detail-general-root-layout" class="irt-full-height">
  <div  class="irt-double-margin-bottom irt-full-width irt-spaced-row" 
        *ngIf="vehicle">
    <h2 class="irt-primary-medium-color irt-no-margin"> Veicolo </h2>

    <button mat-icon-button
            class="irt-accent-background"
            (click)="onVehicleUpdateClick()">
      <mat-icon class="irt-primary-color"> create </mat-icon>
    </button>
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Targa </p>
      <p class="irt-no-margin irt-cursor-pointer irt-hover-text" (click)="onPlateQrClick()"> 
        {{ vehicleContent.plate }} 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Categoria </p>
      <p class="irt-no-margin"> {{ vehicleContent.category }} </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Produttore </p>
      <p class="irt-no-margin"> {{ vehicleContent.brand }} </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Modello </p>
      <p class="irt-no-margin"> {{ vehicleContent.model | titlecase }} </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Alimentazione </p>
      <p class="irt-no-margin"> {{ vehicleContent.supply | titlecase }} </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Anno </p>
      <p class="irt-no-margin"> {{ vehicleContent.year }} </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" *ngIf="vehicleContent.price">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Prezzo di vendita </p>
      <p class="irt-no-margin"> {{ vehicleContent.price }}€ </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" *ngIf="vehicleContent.link">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Link di vendita </p>
      <p class="irt-no-margin"> {{ vehicleContent.link }}€ </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" *ngIf="vehicleContent.km">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Chilometraggio </p>
      <p class="irt-no-margin"> {{ vehicleContent.km }}€ </p>
    </div>
    <hr style="opacity:0.3">
  </div>
</div>
