<div id="irt-vehicle-detail-root-layout" class="irt-full-height">
  <app-toolbar class="irt-toolbar" [currentItem]="navbarItem"></app-toolbar>
  <app-navbar class="irt-navbar" [currentItem]="navbarItem"></app-navbar>
  <app-vehicle-detail-section class="irt-section" [vehicle]="vehicle" (refresh)="onRefreshClick()"
                              (updateVehicleClick)="onVehicleUpdateClick($event)"
                              (deleteVehicleClick)="onVehicleDeleteClick()" *ngIf="vehicle"></app-vehicle-detail-section>
  <div  class="irt-spinner-layout irt-full-width irt-full-height" 
        *ngIf="!vehicle">
    <div  class="irt-padding irt-border-radius" 
          style="background: #F4F4F4;">
      <mat-spinner  [diameter]="30" 
                    color="accent"> </mat-spinner>
    </div>
  </div>
</div>
