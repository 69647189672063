<div id="irt-reservation-detail-general-root-layout">
  <div  class="irt-double-margin-bottom irt-full-width irt-spaced-row">
    <h2 class="irt-primary-medium-color irt-no-margin"> Dettagli </h2>

    <button mat-icon-button
            (click)="onDetailsUpdateClick()"
            class="irt-accent-background">
      <mat-icon class="irt-primary-color"> create </mat-icon>
    </button>
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.payment && reservation.payment.amount">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Importo </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.payment.amount/100 | number: '0.2-2' }}€ 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.start_address">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Luogo di ritiro </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ getPlaceText(reservation.start_address) | titlecase }} 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.end_address">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Luogo di consegna </p>
      <p  class="irt-no-margin irt-right-text" 
          style="margin-left: 8px !important;"> 
        {{ getPlaceText(reservation.end_address) | titlecase }} 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.vehicle_category">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Categoria veicolo </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.vehicle_category }} 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.contract_url">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Contratto iniziale </p>
      <p class="irt-no-margin">
        <a [href]="reservation.contract_url" target="_blank"> link </a> 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.end_contract_url">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Contratto finale </p>
      <p class="irt-no-margin">
        <a [href]="reservation.end_contract_url" target="_blank"> link </a> 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="getPaymentLink()">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Pagamento </p>
      <p class="irt-no-margin">
        <a [href]="getPaymentLink()" target="_blank"> link </a> 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div class="irt-full-width irt-half-margin-bottom" *ngIf="isReservationClosed()">
    <button class="irt-main-button irt-full-width" mat-button (click)="onEndContractClick()">
      <p class="irt-ellipsized-text irt-no-margin"> INVIA CONTRATTO FINALE </p> 
    </button>
  </div>
</div>
