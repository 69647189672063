import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Resource } from '../../core/core.module';
import { ReservationsBuilderVehicleComponent } from '../../reservations/reservations-builder-vehicle/reservations-builder-vehicle.component';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Deductible,
  Reservation,
  ReservationsService,
  Service,
} from '../../core/reservations.service';
import { BehaviorSubject } from 'rxjs';
import { Vehicle } from '../../core/vehicles.service';
import { Router } from '@angular/router';
import { VEHICLE_DETAIL_PATH } from 'src/app/shared/constants';
import { ButtonService } from 'src/app/core/button.service';
import { ReservationDetailStartContractComponent } from '../reservation-detail-start-contract/reservation-detail-start-contract.component';
import { ReservationDetailStartContractNotesComponent } from '../reservation-detail-start-contract-notes/reservation-detail-start-contract-notes.component';

@Component({
  selector: 'app-reservation-detail-vehicle',
  templateUrl: './reservation-detail-vehicle.component.html',
  styleUrls: ['./reservation-detail-vehicle.component.scss'],
})
export class ReservationDetailVehicleComponent implements OnInit {
  @Input() _reservation: BehaviorSubject<Resource>;
  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();
  showButtonSpinner: boolean = false;
  showUpdateSpinner: boolean = false;
  reservationResource: Resource;
  vehicle: Vehicle;

  constructor(
    private dialogService: DialogService,
    private dialog: MatDialog,
    private reservationsService: ReservationsService,
    private router: Router,
    public buttonService: ButtonService
  ) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this._reservation.subscribe((reservation) => {
      if (reservation) {
        this.reservationResource = reservation;
        if (
          this.reservationResource.content &&
          (this.reservationResource.content as Reservation).vehicle_plate
        ) {
          this.vehicle = new Vehicle();
          this.vehicle.category = (
            this.reservationResource.content as Reservation
          ).vehicle_category;
          this.vehicle.plate = (
            this.reservationResource.content as Reservation
          ).vehicle_plate;
          this.vehicle.brand = (
            this.reservationResource.content as Reservation
          ).vehicle_brand;
          this.vehicle.model = (
            this.reservationResource.content as Reservation
          ).vehicle_model;
        }
      }
    });
  }

  onVehicleBuilderClick() {
    this.dialogService
      .openDialog(ReservationsBuilderVehicleComponent, {
        reservation: this.reservationResource,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult && dialogResult.success && dialogResult.vehicle) {
          const vehicle: Resource = dialogResult.vehicle;
          this.dialogService
            .openDialog(ReservationDetailStartContractComponent)
            .afterClosed()
            .subscribe((dialogResult) => {
              if (
                dialogResult &&
                dialogResult.success &&
                dialogResult.km &&
                dialogResult.services &&
                dialogResult.deductibles
              ) {
                const km = dialogResult.km;
                const services = dialogResult.services;
                const deductibles = dialogResult.deductibles;
                const send_contract = dialogResult.send_contract;
                this.dialogService
                  .openDialog(ReservationDetailStartContractNotesComponent, {
                    reservation: this.reservationResource,
                  })
                  .afterClosed()
                  .subscribe((dialogResult) => {
                    if (dialogResult && dialogResult.success) {
                      this.assignVehicle(
                        vehicle,
                        km,
                        services,
                        deductibles,
                        send_contract
                      );
                    }
                  });
              }
            });
        }
      });
  }

  onVehicleClick() {}

  assignVehicle(
    vehicle: Resource,
    km: number,
    services: Service[],
    deductibles: Deductible[],
    send_contract: boolean
  ) {
    this.showButtonSpinner = true;
    this.reservationsService
      .assignVehicle(
        this.reservationResource,
        vehicle,
        km,
        services,
        deductibles,
        send_contract
      )
      .subscribe({
        next: (response) => {
          this.refresh.emit();
          this.showButtonSpinner = false;
        },
        error: (error) => {
          this.refresh.emit();
          this.showButtonSpinner = false;
        },
      });
  }
}
