<div id="irt-reservation-detail-section-root-layout" class="irt-full-height">
  <div id="irt-reservation-detail-section-header-layout" class="irt-full-width irt-spaced-row">
    <div>
      <h1 class="irt-no-margin irt-ellipsized-text"> 
        {{ reservationResource.key.uri.substring(0,8) }} 
      </h1>

      <h3 class="irt-primary-medium-color irt-ellipsized-text">
        {{ reservation.start_date | date: 'short' }} -
        {{ reservation.end_date | date: 'short' }}
      </h3>
    </div>

    <div class="irt-centered-row">
      <div  id="irt-reservation-detail-table-status-layout" 
            class="irt-cursor-pointer"
            (click)="onStatusClick()"
            [ngClass]="getStatusClass()" 
            matTooltip="Modifica stato">
        <h3 class="irt-no-margin"> {{ reservationResource.status }} </h3>
      </div>
      <button mat-icon-button (click)="onReservationDeleteClick()"
              class="irt-half-margin-left" matTooltip="Elimina prenotazione">
        <mat-icon color="warn">delete</mat-icon> 
      </button>
    </div>
  </div>

  <div id="irt-reservation-detail-section-content-layout" class="irt-full-height">
    <div class="irt-third-width irt-full-height irt-primary-medium-background irt-margin-top
                irt-reservation-detail-section-content-layout irt-border-radius">
      <app-reservation-detail-user [_reservation]="_reservation"></app-reservation-detail-user>
    </div>
    <div class="irt-third-width irt-full-height irt-primary-medium-background  irt-margin-top
                irt-reservation-detail-section-content-layout irt-border-radius">
      <app-reservation-detail-general 
        [_reservation]="_reservation"
        (generalUpdate)="onRefreshClick()"
        (endContractClick)="onEndContractClick()"></app-reservation-detail-general>
    </div>
    <div class="irt-third-width irt-full-height irt-primary-medium-background irt-margin-top
                irt-reservation-detail-section-content-layout irt-border-radius">
      <app-reservation-detail-vehicle 
        [_reservation]="_reservation"
        (refresh)="onRefreshClick()"></app-reservation-detail-vehicle>
    </div>
  </div>
</div>
