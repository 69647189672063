import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {API} from 'aws-amplify';
import {Resource} from './core.module';
import {environment} from '../../environments/environment';

export const VEHICLES_API_NAME      : string = 'vehicles';
export const VEHICLES_API_ENDPOINT  : string = environment.API_BASE_URL + '/vehicles';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  createVehicle(token: string, vehicle: Vehicle) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/';
    let init              = {
      response  : true,
      body      : { content: vehicle }
    };
    return from(API.post(apiName, path, init));
  }

  readVehicle(token: string, uri: string) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/' + uri;
    let init              = { response: true };
    return from(API.get(apiName, path, init));
  }

  updateVehicle(token: string, vehicle: Resource) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/' + vehicle.key.uri;
    let init              = {
      response  : true,
      body      : {content: vehicle.content}
    };
    return from(API.put(apiName, path, init));
  }

  deleteVehicle(token: string, vehicle: Resource) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/' + vehicle.key.uri;
    let init              = { response: true };
    return from(API.del(apiName, path, init));
  }

  findVehicles(token: string, page?: number, plate?: string) {
    const VEHICLES_PER_PAGE: number = 30;
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/';
    let queryStringParams = {
      'page': page?page:1,
      'per_page': VEHICLES_PER_PAGE
    }
    if (plate) {
      queryStringParams['plate'] = plate;
    }
    let init = {
      response: true,
      'queryStringParameters': queryStringParams
    };
    return from(API.get(apiName, path, init));
  }

  listAvailableVehicles(startDate: number, endDate: number) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/' + startDate + '/' + endDate;
    let init              = { response: true };
    return from(API.get(apiName, path, init));
  }

  setStatus(vehicle: Resource, status: string) {
    let apiName : string  = VEHICLES_API_NAME;
    let path    : string  = '/' + vehicle.key.uri + '/status/' + status;
    let init              = { response: true };
    return from(API.patch(apiName, path, init));
  }
}

export class Vehicle {
  ext_id      : string;
  plate       : string;
  category    : string;
  brand       : string;
  model       : string;
  year        : number;
  supply      : string;
  is_on_sale  : boolean;
  price       : number;
  img_url     : string;
  damages     : VehicleDamage[];
  revisions   : VehicleRevision[];
  link        : string;
  km          : number;
}

export class VehicleDamage {
  date        : number;
  description : string;
  img_url     : string;
}

export class VehicleRevision {
  date  : number;
  km    : number;
  note  : string;
}
