/** Modules **/
import { NgModule }             from '@angular/core';
import { CommonModule }         from '@angular/common';
import { SharedModule }         from '../shared/shared.module';
import { PlannerRoutingModule } from './planner-routing.module';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatNativeDateModule }      from '@angular/material/core';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatCheckboxModule }        from '@angular/material/checkbox';

/** Components **/
import { PlannerComponent }         from './planner.component';
import { PlannerSectionComponent }  from './planner-section/planner-section.component';
import { PlannerTableComponent }    from './planner-table/planner-table.component';
import { ReactiveFormsModule }      from '@angular/forms';
import { PlannerFiltersComponent }  from './planner-filters/planner-filters.component';

@NgModule({
  declarations: [
    PlannerComponent, 
    PlannerSectionComponent, 
    PlannerTableComponent, 
    PlannerFiltersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PlannerRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatCheckboxModule,
    ReactiveFormsModule
  ]
})
export class PlannerModule { }
