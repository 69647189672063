import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../core/form.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Vehicle, VehicleRevision, VehiclesService} from '../../core/vehicles.service';
import {Resource} from '../../core/core.module';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-vehicle-detail-revisions-add',
  templateUrl: './vehicle-detail-revisions-add.component.html',
  styleUrls: ['./vehicle-detail-revisions-add.component.scss']
})
export class VehicleDetailRevisionsAddComponent implements OnInit {
  formGroup         : FormGroup;
  vehicle           : Resource;
  showButtonSpinner : boolean = false;

  constructor(private formService     : FormService, 
              private dialogRef       : MatDialogRef<VehicleDetailRevisionsAddComponent>,
              private vehiclesService : VehiclesService, 
              private authService     : AuthService,
              @Inject(MAT_DIALOG_DATA) data) {
    this.formGroup  = formService.vehicleRevisionFormGroup;
    this.vehicle    = data.vehicle;
  }

  ngOnInit() {
    this.formGroup.reset();
  }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let date  : number = new Date(this.formGroup.get('date').value).getTime();
      let km    : number = this.formGroup.get('km').value;
      let note  : string = this.formGroup.get('note').value;
      this.authService.getAuthenticatedUser().subscribe(
        user => {
          const revision  : VehicleRevision = new VehicleRevision();
          const token     : string          = user.signInUserSession.idToken.jwtToken;
          revision.date   = date;
          revision.km     = km;
          revision.note   = note;
          (this.vehicle.content as Vehicle).revisions = (this.vehicle.content as Vehicle).revisions?
            (this.vehicle.content as Vehicle).revisions.concat(revision):[revision];
          this.updateVehicle(token);
        }, error => {
          this.authService.signOut();
        }
      );
    }
  }

  updateVehicle(token: string) {
    this.showButtonSpinner = true;
    this.vehiclesService.updateVehicle(token, this.vehicle).subscribe(
      response => {
        this.showButtonSpinner = false;
        this.dialogRef.close({success: true});
      }, error => {
        this.showButtonSpinner = false;
      }
    );
  }
}
