<div id="irt-reservations-builder-vehicle-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
          style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Assegna un veicolo
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-reservations-builder-vehicle-content-layout">
    <form class="irt-form irt-margin-bottom" 
          [formGroup]="vehicleFormGroup">
      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> Cerca per targa... </mat-label>
        <input  matInput 
                type="text" 
                formControlName="plate">
      </mat-form-field>

      <button mat-button 
              class="irt-full-width irt-main-button" 
              [disabled]="showButtonSpinner"
              [ngClass]="buttonService.getDisabledClass(showButtonSpinner)"
              (click)="onSearchClick()">
        <span *ngIf="!showButtonSpinner"> Cerca </span>
        <div  class="irt-spinner-layout" 
              *ngIf="showButtonSpinner">
          <mat-spinner  [diameter]="20" 
                        color="accent"> </mat-spinner>
        </div>
      </button>
    </form>

    <div id="irt-reservations-builder-vehicle-layout"
         class="irt-margin-bottom irt-centered-column irt-primary-medium-background">
      <p  class="irt-centered-text irt-no-margin irt-half-margin-bottom" 
          *ngIf="!vehicle"> 
        Nessun veicolo disponibile trovato 
      </p>
      <p  class="irt-centered-text irt-no-margin irt-half-margin-bottom" 
          *ngIf="vehicle">
        {{ vehicleContent.brand + ' ' + vehicleContent.model }} 
      </p>
      <p  class="irt-centered-text irt-no-margin irt-half-margin-bottom" 
          *ngIf="vehicle">
        Categoria: {{ vehicleContent.category }} 
      </p>
      <p  class="irt-centered-text irt-no-margin" 
          *ngIf="vehicle">
        {{ vehicleContent.plate }} 
      </p>
    </div>

    <!--<form class="irt-form irt-margin-bottom" 
          [formGroup]="kmFormGroup">
      <mat-form-field appearance="outline" class="irt-full-width">
        <mat-label> Aggiungi il chilometraggio </mat-label>
        <input matInput type="number" formControlName="km" min="0" required>
      </mat-form-field>
    </form>-->

    <div class="irt-full-width irt-centered-row">
     <button  mat-button 
              [ngClass]="getButtonClass()" 
              [disabled]="!vehicle" 
              (click)="onConfirmClick()">
        <h3 class="irt-no-margin"> Conferma </h3>
      </button>
    </div>
  </div>
</div>
