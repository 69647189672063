import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../core/form.service';
import {Router} from '@angular/router';
import {RESERVATIONS_PATH} from '../shared/constants';
import {AuthService} from '../core/auth.service';
import {DialogService} from '../core/dialog.service';
import {environment} from '../../environments/environment';
import {AuthForgotPssDialogComponent} from './auth-forgot-pss-dialog/auth-forgot-pss-dialog.component';
import {AuthForgotPssConfirmDialogComponent} from './auth-forgot-pss-confirm-dialog/auth-forgot-pss-confirm-dialog.component';
import {InfoDialogComponent} from '../shared/info-dialog/info-dialog.component';
import {PASSWORD_RESET_SUCCESS_MESSAGE, PASSWORD_RESET_SUCCESS_TITLE} from '../shared/translations';
import { ButtonService } from '../core/button.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  formGroup         : FormGroup;
  matcher           : ErrorStateMatcher;
  showButtonSpinner : boolean = false;
  isPasswordVisible : boolean = false;

  constructor(private authService   : AuthService, 
              private dialogService : DialogService,
              private formService   : FormService, 
              private router        : Router, 
              private dialog        : MatDialog,
              public  buttonService : ButtonService) {
    this.formGroup            = formService.loginFormGroup;
    this.matcher              = formService.matcher;
    this.dialogService.dialog = this.dialog;
  }

  onLoginClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let email: string       = this.formGroup.get('email').value;
      let password: string    = this.formGroup.get('password').value;
      this.showButtonSpinner  = true;
      this.authService.signIn(email, password).subscribe(
        response => {
          if (!environment.production) {
            console.log(response);
          }
          this.router.navigate([RESERVATIONS_PATH]);
          this.showButtonSpinner = false;
        },
        error => {
          this.dialogService.openErrorDialog(error.message);
          this.showButtonSpinner = false;
        }
      );
    }
  }

  onForgotPssClick() {
    this.dialogService.openDialog(AuthForgotPssDialogComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success) {
          this.onForgotPssRequestSent(dialogResult.email);
        } else if (dialogResult && !dialogResult.success) {
          this.dialogService.openErrorDialog(dialogResult.error);
        }
      }
    );
  }

  onForgotPssRequestSent(email: string) {
    this.dialogService.openDialog(AuthForgotPssConfirmDialogComponent, {email: email}).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success) {
          this.dialogService.openDialog(InfoDialogComponent, {
            title   : PASSWORD_RESET_SUCCESS_TITLE, 
            message : PASSWORD_RESET_SUCCESS_MESSAGE
          });
        } else if (dialogResult && !dialogResult.success) {
          this.dialogService.openErrorDialog(dialogResult.error);
        }
      }
    );
  }
}
