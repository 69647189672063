<div class="irt-spaced-row">
  <div class="irt-centered-row">
    <button
      mat-icon-button
      (click)="onRefreshClick()"
      matTooltip="Ricarica prenotazioni">
      <mat-icon> refresh </mat-icon>
    </button>
    <p
      id="irt-reservations-update-layout"
      class="irt-half-margin-left">
      Aggiornato il {{ updateTime | date : 'short' }}
    </p>
  </div>

  <form [formGroup]="formGroup">
    <button
      mat-icon-button
      (click)="onFiltersClick()">
      <mat-icon> tune </mat-icon>
    </button>

    <mat-form-field class="irt-half-margin-left">
      <input
        (change)="onInputChange($event.target)"
        (input)="onSearchChange($event)"
        matInput
        type="text"
        placeholder="Cerca per email..."
        formControlName="reservations_search" />
    </mat-form-field>
  </form>
</div>

<div
  class="irt-table-container"
  style="overflow-x: scroll">
  <table
    class="irt-full-width"
    mat-table
    [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="irt-border-top-left-radius">
        <h2 class="irt-centered-text">ID</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text"
          *ngIf="reservation.key.uri">
          {{ reservation.key.uri.substring(0, 8) }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Cliente</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <div
          *ngIf="
            reservation.content &&
            reservation.content.customer &&
            reservation.content.customer.type == privateCustomerType
          ">
          <p class="irt-centered-text">
            <span *ngIf="reservation.content.customer.first_name">
              {{ reservation.content.customer.first_name }}
            </span>
            <span *ngIf="reservation.content.customer.last_name">
              {{ reservation.content.customer.last_name }}
            </span>
          </p>
          <p
            class="irt-centered-text"
            *ngIf="
              !reservation.content.customer.first_name &&
              !reservation.content.customer.last_name
            ">
            -
          </p>
        </div>

        <div
          *ngIf="
            reservation.content &&
            reservation.content.customer &&
            reservation.content.customer.type == companyCustomerType
          ">
          <p class="irt-centered-text">
            <span *ngIf="reservation.content.customer.business_name">
              {{ reservation.content.customer.business_name }}
            </span>
          </p>
          <p
            class="irt-centered-text"
            *ngIf="!reservation.content.customer.business_name">
            -
          </p>
        </div>

        <p
          class="irt-centered-text"
          *ngIf="!reservation.content || !reservation.content.customer">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Email</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text"
          *ngIf="
            reservation.content &&
            reservation.content.customer &&
            reservation.content.customer.email
          ">
          {{ reservation.content.customer.email }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="
            !reservation.content ||
            !reservation.content.customer ||
            !reservation.content.customer.email
          ">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Telefono</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text"
          *ngIf="
            reservation.content &&
            reservation.content.customer &&
            reservation.content.customer.phone &&
            reservation.content.customer.phone.value
          ">
          +
          {{ reservation.content.customer.phone.prefix }}
          {{ reservation.content.customer.phone.value }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="
            !reservation.content ||
            !reservation.content.customer ||
            !reservation.content.customer.phone ||
            !reservation.content.customer.phone.value
          ">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Data inizio</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text irt-ellipsized-text"
          *ngIf="reservation.content && reservation.content.start_date">
          {{ reservation.content.start_date | date : 'short' }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="!reservation.content || !reservation.content.start_date">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Data fine</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text irt-ellipsized-text"
          *ngIf="reservation.content && reservation.content.end_date">
          {{ reservation.content.end_date | date : 'short' }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="!reservation.content || !reservation.content.end_date">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="vehicle">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Veicolo</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text irt-ellipsized-text"
          *ngIf="
            reservation.content &&
            reservation.content.vehicle_brand &&
            reservation.content.vehicle_model
          ">
          {{
            reservation.content.vehicle_brand +
              ' ' +
              reservation.content.vehicle_model
          }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="
            !reservation.content ||
            !reservation.content.vehicle_brand ||
            !reservation.content.vehicle_model
          ">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="plate">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Targa</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text irt-ellipsized-text"
          *ngIf="reservation.content && reservation.content.vehicle_plate">
          {{ reservation.content.vehicle_plate }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="!reservation.content || !reservation.content.vehicle_plate">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th
        mat-header-cell
        *matHeaderCellDef>
        <h2 class="irt-centered-text">Importo</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <p
          class="irt-centered-text irt-ellipsized-text"
          *ngIf="reservation.content && reservation.content.payment">
          €
          {{
            reservation.content.payment.amount / 100
              | number : '0.1-2' : 'it-IT'
          }}
        </p>
        <p
          class="irt-centered-text"
          *ngIf="!reservation.content || !reservation.content.payment">
          -
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="irt-border-top-right-radius">
        <h2 class="irt-centered-text">Stato</h2>
      </th>

      <td
        mat-cell
        *matCellDef="let reservation">
        <div
          class="irt-reservations-table-status-layout irt-ellipsized-text irt-padding"
          [ngClass]="getStatusClass(reservation)"
          *ngIf="reservation.status">
          <p class="irt-centered-text irt-no-margin">
            {{ reservation.status | lowercase }}
          </p>
        </div>
        <p
          class="irt-centered-text"
          *ngIf="!reservation.status">
          -
        </p>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      class="irt-cursor-pointer"
      (click)="onReservationClick(rowData)"
      mat-row
      *matRowDef="let rowData; columns: columnsToDisplay"></tr>
  </table>
</div>
