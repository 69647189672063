import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InfoDialogComponent } from '../shared/info-dialog/info-dialog.component';
import { ERROR_DIALOG_TITLE } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialog: MatDialog;

  openDialog(component: any,data?: any) {
    const dialogConfig      = new MatDialogConfig();
    dialogConfig.autoFocus  = false;
    if (data != null) {
      dialogConfig.data = data;
    }
    return this.dialog.open(component, dialogConfig);
  }

  openErrorDialog(message: string) {
    return this.openDialog(InfoDialogComponent, {title: ERROR_DIALOG_TITLE, message: message});
  }
}
