import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource } from 'src/app/core/core.module';
import { FormService } from 'src/app/core/form.service';
import { Reservation, ReservationsService } from 'src/app/core/reservations.service';

@Component({
  selector: 'app-reservation-detail-start-contract-notes',
  templateUrl: './reservation-detail-start-contract-notes.component.html',
  styleUrls: ['./reservation-detail-start-contract-notes.component.scss']
})
export class ReservationDetailStartContractNotesComponent implements OnInit {
  reservation: Resource;
  notes: string[];
  formGroup: FormGroup;
  isRequestRunning: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ReservationDetailStartContractNotesComponent>,
    private formService: FormService,
    private reservationService: ReservationsService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.reservation = data.reservation;
    this.formGroup = this.formService.notesFormGroup;
  }

  ngOnInit(): void {
    this.notes = [];
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onAddNoteClick() {
    if (this.formService.validateForm(this.formGroup)) {
      const note: string = this.formGroup.get('note').value;
      this.notes.push(note);
      this.formGroup.reset();
    }
  }

  onConfirmClick() {
    this.isRequestRunning = true;
    (this.reservation.content as Reservation).notes = this.notes;
    this.reservationService.updateReservation('', this.reservation).subscribe({
      next: (response) => {
        this.isRequestRunning = false;
        this.dialogRef.close({ success: true });
      },
      error: (error) => {
        this.isRequestRunning = false;
      }
    });
  }

  getDisabledClass() {
    return {
      'irt-main-button'           : !this.isRequestRunning,
      'irt-main-disabled-button'  : this.isRequestRunning
    }
  }
}
