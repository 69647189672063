import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AUTH_PATH, RESERVATIONS_PATH } from './shared/constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: AUTH_PATH ,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
