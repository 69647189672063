import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';

@Component({
  selector: 'app-reservations-filters',
  templateUrl: './reservations-filters.component.html',
  styleUrls: ['./reservations-filters.component.scss'],
})
export class ReservationsFiltersComponent implements OnInit {
  formGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ReservationsFiltersComponent>,
    private formService: FormService
  ) {
    this.formGroup = this.formService.reservationsFilterFormGroup;
  }

  ngOnInit() {}

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    const plate: string = this.formGroup.get('plate').value.toUpperCase();
    const startDate: number = new Date(
      this.formGroup.get('start_date').value
    ).getTime();
    const endDate: number = new Date(
      this.formGroup.get('end_date').value
    ).getTime();
    this.dialogRef.close({
      start_date: startDate,
      end_date: endDate,
      plate: plate,
    });
  }

  onFiltersRemoveClick() {
    this.formGroup.reset();
  }
}
