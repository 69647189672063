import {Component, Input} from '@angular/core';
import {NavbarItem, PLANNER_PATH, RESERVATIONS_PATH, USERS_PATH, VEHICLES_PATH} from '../constants';
import {NavbarItemName} from '../translations';
import {Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ])
  ]
})
export class ToolbarComponent {
  @Input() currentItem: NavbarItem;
  showFullScreenMenu: boolean = false;
  items: NavbarItem[] = [
    {
      name: NavbarItemName.RESERVATIONS,
      icon: 'event',
      text: NavbarItemName.RESERVATIONS,
      path: RESERVATIONS_PATH
    },
    {
      name: NavbarItemName.VEHICLES,
      icon: 'directions_car',
      text: NavbarItemName.VEHICLES,
      path: VEHICLES_PATH
    },
    {
      name: NavbarItemName.USERS,
      icon: 'face',
      text: NavbarItemName.USERS,
      path: USERS_PATH
    },
    {
      name: NavbarItemName.PLANNER,
      icon: 'assignment',
      text: NavbarItemName.PLANNER,
      path: PLANNER_PATH
    }
  ];

  constructor(private router: Router) {}

  onFullScreenMenuToggleClick() {
    this.showFullScreenMenu = !this.showFullScreenMenu;
  }

  onItemClick(item: NavbarItem) {
    this.showFullScreenMenu = false;
    this.router.navigate([item.path]);
  }

  getItemTextClass(item: NavbarItem) {
    return {
      'irt-toolbar-menu-item-selected-text'   : this.currentItem && item.name == this.currentItem.name,
      'irt-toolbar-menu-item-unselected-text' : !this.currentItem || item.name != this.currentItem.name
    }
  }
}
