import {Component, OnInit} from '@angular/core';
import {NavbarItem, RESERVATIONS_PATH, VEHICLES_PATH} from '../shared/constants';
import {Resource} from '../core/core.module';
import {ActivatedRoute, Router} from '@angular/router';
import {NavbarItemName} from '../shared/translations';
import {Vehicle, VehiclesService} from '../core/vehicles.service';
import {AuthService} from '../core/auth.service';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss']
})
export class VehicleDetailComponent implements OnInit {
  vehicleUri  : string;
  navbarItem  : NavbarItem;
  vehicle     : Resource;

  constructor(private route: ActivatedRoute, private router: Router,
              private authService: AuthService, private vehiclesService: VehiclesService) {
    this.vehicleUri       = this.route.snapshot.paramMap.get('vehicle_uri');
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.VEHICLES;
  }

  ngOnInit() {
    if (this.vehicleUri) {
      this.readVehicle();
    } else {
      this.router.navigate([VEHICLES_PATH]);
    }
  }

  onRefreshClick() {
    if (this.vehicleUri) {
      this.vehicle = null;
      this.readVehicle();
    } else {
      this.router.navigate([VEHICLES_PATH]);
    }
  }

  readVehicle() {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.vehiclesService.readVehicle(token, this.vehicleUri).subscribe(
          response => {
            this.vehicle = response.data;
          }, error => {
            this.router.navigate([RESERVATIONS_PATH]);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  onVehicleUpdateClick(vehicle: Vehicle) {
    this.updateVehicle(vehicle);
  }

  onVehicleDeleteClick() {
    this.deleteVehicle();
  }

  updateVehicle(vehicle: Vehicle) {
    vehicle.is_on_sale      = (this.vehicle.content as Vehicle).is_on_sale;
    vehicle.damages         = (this.vehicle.content as Vehicle).damages;
    vehicle.revisions       = (this.vehicle.content as Vehicle).revisions;
    let resource: Resource  = new Resource();
    resource.content        = vehicle;
    resource.key            = this.vehicle.key;
    this.vehicle            = null;
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.vehiclesService.updateVehicle(token, resource).subscribe(
          response => {
            this.onRefreshClick();
          }, error => {}
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  deleteVehicle() {
    let vehicle: Resource   = this.vehicle;
    this.vehicle            = null;
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.vehiclesService.deleteVehicle(token, vehicle).subscribe(
          response => {
            this.router.navigate([VEHICLES_PATH]);
          }, error => {
            console.error(error);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }
}
