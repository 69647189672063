import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RESERVATIONS_PATH } from '../shared/constants';
import { ReservationsComponent } from './reservations.component';

const routes: Routes = [
  {
    path: RESERVATIONS_PATH,
    component: ReservationsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservationsRoutingModule { }
