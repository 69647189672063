<div id="irt-vehicles-builder-basics-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Aggiungi un veicolo 
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <form id="irt-vehicles-builder-basics-form" class="irt-form" [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Targa </mat-label>
      <input matInput type="text" formControlName="plate" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Alimentazione </mat-label>
      <mat-select formControlName="supply" required>
        <mat-option *ngFor="let supply of supplies" [value]="supply"> 
          {{ supply | titlecase }} 
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Produttore </mat-label>
      <input matInput type="text" formControlName="brand" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Modello </mat-label>
      <input matInput type="text" formControlName="model" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Categoria </mat-label>
      <input matInput type="text" formControlName="category" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Anno </mat-label>
      <input matInput type="text" formControlName="year" required>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Prezzo di vendita </mat-label>
      <input matInput type="number" formControlName="price">
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Link di vendita </mat-label>
      <input matInput type="text" formControlName="link">
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-half-width irt-vehicles-builder-basics-field">
      <mat-label> Chilometraggio </mat-label>
      <input matInput type="number" formControlName="km" min="0">
    </mat-form-field>

    <div class="irt-full-width irt-centered-row irt-margin-top">
      <button mat-button [ngClass]="getButtonClass()" [disabled]="!validateForm()" (click)="onConfirmClick()">
        <h3 class="irt-no-margin"> Conferma </h3>
      </button>
    </div>
  </form>
</div>
