import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { Reservation } from 'src/app/core/reservations.service';
import { HOURS_OF_THE_DAY, MINUTES_OF_THE_HOUR } from 'src/app/shared/constants';

@Component({
  selector: 'app-reservations-builder-time',
  templateUrl: './reservations-builder-time.component.html',
  styleUrls: ['./reservations-builder-time.component.scss']
})
export class ReservationsBuilderTimeComponent implements OnInit {
  reservation       : Reservation;
  formGroup         : FormGroup;
  matcher           : ErrorStateMatcher;
  hours             : string[] = HOURS_OF_THE_DAY;
  minutes           : string[] = MINUTES_OF_THE_HOUR;

  constructor(
    private formService : FormService,
    private dialogRef   : MatDialogRef<ReservationsBuilderTimeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.reservation            = data.reservation;
    this.formGroup              = this.formService.reservationTimeFormGroup;
    this.matcher                = this.formService.matcher;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.reservation.start_address) {
      this.formGroup.get('start_address').setValue(this.reservation.start_address);
    }
    if (this.reservation.end_address) {
      this.formGroup.get('end_address').setValue(this.reservation.end_address);
    }
    if (this.reservation.start_date) {
      const start_date: Date = new Date(this.reservation.start_date);
      this.formGroup.get('start_date').setValue(start_date);
      this.formGroup.get('start_hour').setValue(this.hours[start_date.getHours()]);
      this.formGroup.get('start_minute').setValue(this.hours[start_date.getMinutes()]);
    }
    if (this.reservation.end_date) {
      const end_date: Date = new Date(this.reservation.end_date);
      this.formGroup.get('end_date').setValue(end_date);
      this.formGroup.get('end_hour').setValue(this.hours[end_date.getHours()]);
      this.formGroup.get('end_minute').setValue(this.hours[end_date.getMinutes()]);
    }
    if (this.reservation.payment && this.reservation.payment.amount) {
      this.formGroup.get('price').setValue(this.reservation.payment.amount/100);
    }
    if (this.reservation.vehicle_category) {
      this.formGroup.get('vehicle_category').setValue(this.reservation.vehicle_category);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.validateForm()) {
      this.buildReservation();
      this.dialogRef.close({
        success     : true,
        reservation : this.reservation
      });
    }
  }

  buildReservation() {
    if (this.formGroup.get('price').value) {
      this.reservation.payment = {
        amount: +this.formGroup.get('price').value.toString().replace(',','.') * 100,
        payment_intent_client_secret: ''
      }
    }
    this.reservation.start_address      = this.formGroup.get('start_address').value;
    this.reservation.end_address        = this.formGroup.get('end_address').value;
    let startDate: Date                 = new Date(this.formGroup.get('start_date').value);
    let startHour: number               = +this.formGroup.get('start_hour').value;
    let startMinute: number             = +this.formGroup.get('start_minute').value;
    startDate.setHours(startHour, startMinute);
    let endDate: Date                   = new Date(this.formGroup.get('end_date').value);
    let endHour: number                 = +this.formGroup.get('end_hour').value;
    let endMinute: number               = +this.formGroup.get('end_minute').value;
    endDate.setHours(endHour, endMinute);
    this.reservation.start_date         = startDate.getTime();
    this.reservation.end_date           = endDate.getTime();
    this.reservation.vehicle_category   = this.formGroup.get('vehicle_category').value;
  }

  validateForm() {
    return this.formService.validateForm(this.formGroup);
  }

  getButtonClass() {
    return {
      'irt-main-button'           : this.validateForm(),
      'irt-main-disabled-button'  : !this.validateForm()
    }
  }
}
