/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { SharedModule }                     from '../shared/shared.module';
import { UserDetailsModule }                from '../user-details/user-details.module';
import { UsersRoutingModule }               from './users-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';

/** Components **/
import { UsersComponent }         from './users.component';
import { UsersSectionComponent }  from './users-section/users-section.component';
import { UsersTableComponent }    from './users-table/users-table.component';
import { UsersFiltersComponent }  from './users-filters/users-filters.component';

@NgModule({
  declarations: [
    UsersComponent, 
    UsersSectionComponent, 
    UsersTableComponent, 
    UsersFiltersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UserDetailsModule,
    UsersRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  entryComponents: [
    UsersFiltersComponent
  ]
})
export class UsersModule { }
