import {Component, Input, OnInit} from '@angular/core';
import {Resource} from '../../core/core.module';
import {BehaviorSubject} from 'rxjs';
import {Reservation} from '../../core/reservations.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';

@Component({
  selector: 'app-reservation-detail-user',
  templateUrl: './reservation-detail-user.component.html',
  styleUrls: ['./reservation-detail-user.component.scss']
})
export class ReservationDetailUserComponent implements OnInit {
  @Input() _reservation : BehaviorSubject<Resource>;
  reservationResource   : Resource;
  reservation           : Reservation;

  constructor(
    private router: Router,
    private dialog_service: DialogService,
    private dialog: MatDialog,
    ) {
      this.dialog_service.dialog = this.dialog;
    }

  ngOnInit() {
    this._reservation.subscribe(
      reservation => {
        if (reservation) {
          this.reservationResource  = reservation;
          this.reservation          = reservation.content as Reservation;
        }
      }
    );
  }

  onUsersClick() {
    this.router.navigate(['/users']);
  }
}
