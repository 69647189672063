/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { VehiclesRoutingModule }            from './vehicles-routing.module';
import { SharedModule }                     from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatSelectModule }          from '@angular/material/select';
import { MatTableModule }           from '@angular/material/table';
import { MatTooltipModule }         from '@angular/material/tooltip';

/** Components **/
import { VehiclesComponent }              from './vehicles.component';
import { VehiclesSectionComponent }       from './vehicles-section/vehicles-section.component';
import { VehiclesTableComponent }         from './vehicles-table/vehicles-table.component';
import { VehiclesBuilderBasicsComponent } from './vehicles-builder-basics/vehicles-builder-basics.component';
import { VehiclesBuilderImageComponent }  from './vehicles-builder-image/vehicles-builder-image.component';
import { VehiclesFiltersComponent }       from './vehicles-filters/vehicles-filters.component';

@NgModule({
  declarations: [
    VehiclesComponent,
    VehiclesSectionComponent,
    VehiclesTableComponent,
    VehiclesBuilderBasicsComponent,
    VehiclesBuilderImageComponent,
    VehiclesFiltersComponent
  ],
  imports: [
    CommonModule,
    VehiclesRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  entryComponents: [
    VehiclesBuilderBasicsComponent,
    VehiclesBuilderImageComponent
  ]
})
export class VehiclesModule { }
