<div id="irt-reservation-detail-user-root-layout">
  <div  class="irt-double-margin-bottom irt-full-width irt-spaced-row">
    <h2 class="irt-primary-medium-color irt-no-margin"> Utente </h2>
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.business_name">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Azienda </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.customer.business_name }} 
      </p>
    </div>
    <hr style="opacity:0.3">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.email">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Email conducente </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.customer.email }} 
      </p>
    </div>
    <hr style="opacity: 0.3;">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.first_name">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Nome conducente </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.customer.first_name }} 
      </p>
    </div>
    <hr style="opacity: 0.3;">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.last_name">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Cognome conducente </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        {{ reservation.customer.last_name }} 
      </p>
    </div>
    <hr style="opacity: 0.3;">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.phone && reservation.customer.phone.value">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Telefono </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
        +
        {{ reservation.customer.phone.prefix }}
        {{ reservation.customer.phone.value }} 
      </p>
    </div>
    <hr style="opacity: 0.3;">
  </div>

  <div  class="irt-margin-bottom" 
        *ngIf="reservation.customer.code">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Cod. fiscale </p>
      <p  class="irt-no-margin irt-right-text"
          style="margin-left: 8px !important;"> 
          {{ reservation.customer.code }} 
      </p>
    </div>
    <hr style="opacity: 0.3;">
  </div>

  <div  class="irt-margin-bottom"
        style="margin-top: -12px;">
    <div class="irt-spaced-row">
      <p class="irt-primary-medium-color irt-no-margin irt-small-text"> Più informazioni </p>
      <button mat-icon-button (click)="onUsersClick()">
        <mat-icon> visibility </mat-icon>
      </button>
    </div>
    <hr style="opacity:0.3; margin-top: -2px;">
  </div>
</div>
