<div id="irt-reservations-section-root-layout" class="irt-full-height">
  <div class="irt-full-width irt-spaced-row">
    <h1 class="irt-no-margin"> Prenotazioni </h1>
    <button mat-fab 
            class="irt-centered-row" 
            (click)="onAddReservationClick()" 
            matTooltip="Aggiungi prenotazione">
      <mat-icon> add </mat-icon>
    </button>
  </div>

  <div class="irt-double-margin-top">
    <app-reservations-table [_reservations]="_reservations" 
                            (refreshClick)="onRefreshClick()"
                            (reloadReservations)="onReservationsReloaded($event)" 
                            (filtersClick)="onFiltersClick()"
                            *ngIf="_reservations.getValue() && _reservations.getValue().length>0"></app-reservations-table>
  </div>

  <div class="irt-full-width irt-centered-row irt-margin-top"
       *ngIf="_reservations.getValue() && _reservations.getValue().length>0 && currentPage < totalPages">
    <button mat-button 
            class="irt-main-button" 
            [disabled]="showLoadButtonSpinner"
            [ngClass]="buttonService.getDisabledClass(showLoadButtonSpinner)"
            (click)="onLoadClick()">
      <span *ngIf="!showLoadButtonSpinner"> Carica altro </span>
      <div  class="irt-spinner-layout" 
            *ngIf="showLoadButtonSpinner">
        <mat-spinner  [diameter]="20" 
                      color="accent"> </mat-spinner>
      </div>
    </button>
  </div>

  <div  class="irt-centered-column"
        style="height: calc(100% - 104px)"  
        *ngIf="_reservations.getValue() && _reservations.getValue().length<=0">
    <h3> Nessuna prenotazione trovata </h3>
    <div class="irt-centered-column irt-wrapped-row">
      <button mat-button 
              style="margin: 12px; min-width: 250px;"
              class="irt-secondary-button"
              (click)="onRefreshClick()">
        <span> Ricarica prenotazioni </span>
      </button>
      <button mat-button
              style="margin: 12px; min-width: 250px;"
              class="irt-main-button"
              (click)="onAddReservationClick()">
        <span> Aggiungi una prenotazione </span>
      </button>
    </div>
  </div>

  <div  class="irt-spinner-layout irt-full-width irt-centered-row"
        style="height: calc(100% - 104px)" 
        *ngIf="!_reservations.getValue()">
    <div  class="irt-padding irt-border-radius" 
          style="background: #F4F4F4;">
      <mat-spinner  [diameter]="30" 
                    color="accent"> </mat-spinner>
    </div>
  </div>
</div>
