import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Resource} from '../../core/core.module';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {FormService} from '../../core/form.service';
import {
  VEHICLE_DETAIL_PATH,
  VehicleState
} from '../../shared/constants';

@Component({
  selector: 'app-vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss']
})
export class VehiclesTableComponent implements OnInit {
  @Input()  _vehicles       : BehaviorSubject<Resource[]>;
  @Output() refreshClick    : EventEmitter<void>    = new EventEmitter<void>();
  @Output() reloadVehicles  : EventEmitter<string>  = new EventEmitter<string>();
  formGroup         : FormGroup;
  updateTime        : number;
  columnsToDisplay  : string[]  = [
    'id',     'plate',    'category',   'brand', 
    'model',  'year',     'sales',      'sales_link', 
    'supply', 'status'
  ];
  dataSource = new MatTableDataSource<Resource>([]);

  constructor(private router: Router, private formService: FormService) {
    this.formGroup = this.formService.vehicleSearchFormGroup;
  }

  ngOnInit() {
    this._vehicles.subscribe(
      vehicles => {
        if (vehicles) {
          this.dataSource.data  = vehicles;
          this.updateTime       = new Date().getTime();
          this.setupTableColumns();
        }
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setupTableColumns();
  }

  onVehicleClick(vehicle: Resource) {
    this.router.navigate([VEHICLE_DETAIL_PATH + '/' + vehicle.key.uri]);
  }

  onRefreshClick() {
    this.refreshClick.emit();
  }

  onInputChange(event) {
    this.reloadVehicles.emit(this.formGroup.get('vehicles_search').value);
  }

  onSearchChange(event) {
    //console.log(event.data);
  }

  setupTableColumns() {
    /*if (window.innerWidth < STYLES_VARIABLES.get(MD_CLASS_NAME)) {
      this.columnsToDisplay = ['plate', 'brand', 'model', 'status'];
    } else if (window.innerWidth >= STYLES_VARIABLES.get(MD_CLASS_NAME)
      && window.innerWidth < STYLES_VARIABLES.get(LG_CLASS_NAME)) {
      this.columnsToDisplay = ['plate', 'category', 'brand', 'model', 'year', 'status'];
    } else {
      this.columnsToDisplay = ['plate', 'category', 'brand', 'model', 'year', 'status'];
    }*/
  }

  getStatusClass(vehicle: Resource) {
    return {
      'irt-vehicle-free-status'       : vehicle.status == VehicleState.FREE,
      'irt-vehicle-scheduled-status'  : vehicle.status == VehicleState.SCHEDULED,
      'irt-vehicle-busy-status'       : vehicle.status == VehicleState.BUSY,
      'irt-vehicle-hidden-status'     : vehicle.status == VehicleState.HIDDEN,
    };
  }
}
