import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';
import { VehicleState } from 'src/app/shared/constants';

@Component({
  selector: 'app-vehicle-detail-status',
  templateUrl: './vehicle-detail-status.component.html',
  styleUrls: ['./vehicle-detail-status.component.scss']
})
export class VehicleDetailStatusComponent implements OnInit {
  previousStatus  : VehicleState;
  formGroup       : FormGroup;
  states          : VehicleState[] = [ VehicleState.FREE, VehicleState.SCHEDULED, VehicleState.BUSY, VehicleState.HIDDEN ];

  constructor(private formService : FormService,
              private dialogRef   : MatDialogRef<VehicleDetailStatusComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.formGroup      = formService.vehicleDetailStatusFormGroup;
    this.previousStatus = data.status;
  }

  ngOnInit(): void {
    this.formGroup.get('status').setValue(this.previousStatus);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      this.dialogRef.close({ success: true, status: this.formGroup.get('status').value });
    }
  }
}
