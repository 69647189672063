<div id="irt-users-filters-dialog-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Filtri sugli utenti 
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-users-filters-dialog-content-layout" class="irt-full-width">
    <h3> Scegli nome e cognome del conducente da ricercare </h3>

    <form [formGroup]="formGroup" class="irt-spaced-row">
      <mat-form-field appearance="outline" class="irt-half-width">
        <mat-label> Nome conducente </mat-label>
        <input matInput type="text" formControlName="first_name">
      </mat-form-field>

      <mat-form-field appearance="outline" class="irt-half-width irt-reservation-builder-basics-field">
        <mat-label> Cognome conducente </mat-label>
        <input matInput type="text" formControlName="last_name">
      </mat-form-field>
    </form>

    <p  class="irt-no-margin irt-cursor-pointer irt-right-text irt-primary-medium-color" 
        (click)="onFiltersRemoveClick()"> 
        <u> Cancella filtri  </u>
    </p>
  </div>

  <div class="irt-full-width irt-centered-row irt-double-margin-bottom">
    <button class="irt-main-button" mat-button (click)="onDialogConfirmClick()">
      <span> Conferma </span> 
    </button>
  </div>
</div>
