<div id="irt-vehicle-detail-damages-add-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Aggiungi danno
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <form id="irt-vehicle-detail-damages-add-content-layout" class="irt-form" [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="irt-full-width">
      <mat-label> Data </mat-label>
      <input matInput type="text" required
             formControlName="date"
             [matDatepicker]="datePicker">
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-full-width">
      <mat-label> Descrizione </mat-label>
      <textarea matInput type="text" formControlName="description" required></textarea>
    </mat-form-field>
  </form>

  <div class="irt-full-width irt-centered-row irt-double-margin-bottom">
    <button class="irt-main-button" mat-button (click)="onDialogConfirmClick()">
      <p class="irt-no-margin" *ngIf="!showButtonSpinner"> Aggiungi danno </p>
      <div class="irt-spinner-layout" *ngIf="showButtonSpinner">
        <mat-spinner [diameter]="20" color="accent"> </mat-spinner>
      </div>
    </button>
  </div>
</div>
