import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Resource} from '../../core/core.module';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {VEHICLE_DETAIL_PATH, VehicleState} from '../../shared/constants';

@Component({
  selector: 'app-planner-table',
  templateUrl: './planner-table.component.html',
  styleUrls: ['./planner-table.component.scss']
})
export class PlannerTableComponent implements OnInit {
  @Input() vehicles : Resource[];
  updateTime        : number;
  columnsToDisplay  : string[]  = [
    'plate',  'category', 'brand',      'model', 
    'year',   'sales',    'sales_link', 'supply',
    'status'
  ];
  dataSource                    = new MatTableDataSource<Resource>([]);

  constructor(private router: Router) {}

  ngOnInit() {
    this.dataSource.data  = this.vehicles;
    this.updateTime       = new Date().getTime();
    this.setupTableColumns();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setupTableColumns();
  }

  onVehicleClick(vehicle: Resource) {
    this.router.navigate([VEHICLE_DETAIL_PATH + '/' + vehicle.key.uri]);
  }

  setupTableColumns() {
    /*if (window.innerWidth < STYLES_VARIABLES.get(MD_CLASS_NAME)) {
      this.columnsToDisplay = ['plate', 'brand', 'model', 'status'];
    } else if (window.innerWidth >= STYLES_VARIABLES.get(MD_CLASS_NAME)
      && window.innerWidth < STYLES_VARIABLES.get(LG_CLASS_NAME)) {
      this.columnsToDisplay = ['plate', 'category', 'brand', 'model', 'year', 'status'];
    } else {
      this.columnsToDisplay = ['plate', 'category', 'brand', 'model', 'year', 'status'];
    }*/
  }

  getStatusClass(vehicle: Resource) {
    return {
      'irt-vehicle-free-status'       : vehicle.status == VehicleState.FREE,
      'irt-vehicle-scheduled-status'  : vehicle.status == VehicleState.SCHEDULED,
      'irt-vehicle-busy-status'       : vehicle.status == VehicleState.BUSY,
      'irt-vehicle-hidden-status'     : vehicle.status == VehicleState.HIDDEN,
    };
  }
}
