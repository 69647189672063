<div id="irt-user-details-dialog-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Dettaglio utente
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-user-details-dialog-content-layout" class="irt-full-width irt-double-margin-bottom">
    <p class="irt-no-margin irt-primary-medium-color" 
        *ngIf="isCompanyStored()"> 
        Generalità azienda
  </p>
    <hr *ngIf="isCompanyStored()" style="opacity:0.3">
    <p class="irt-no-margin" *ngIf="userContent.business_name"> {{ userContent.business_name }} </p>
    <p class="irt-no-margin" *ngIf="userContent.vat">           {{ userContent.vat }} </p>
    <p class="irt-no-margin" *ngIf="userContent.dest_code">     {{ userContent.dest_code }} </p>

    <p class="irt-no-margin irt-primary-medium-color irt-margin-top"> 
      Generalità conducente 
    </p>
    <hr style="opacity:0.3">
    <p class="irt-no-margin"> {{ userContent.email }} </p>
    <p class="irt-no-margin"> 
      {{ userContent.first_name }} 
      <span *ngIf="userContent.last_name"> {{ userContent.last_name }} </span> 
    </p>
    <p class="irt-no-margin" *ngIf="userContent.phone.value"> 
      +{{ userContent.phone.prefix }} {{ userContent.phone.value }} 
    </p>
    <p class="irt-no-margin"> {{ userContent.code }} </p>

    <p class="irt-no-margin irt-primary-medium-color irt-margin-top" 
        *ngIf="isAddressStored()"> 
        Indirizzo conducente 
    </p>
    <hr style="opacity:0.3">
    <p class="irt-no-margin" *ngIf="userContent.address">   {{ userContent.address }} </p>
    <p class="irt-no-margin" *ngIf="userContent.city">      {{ userContent.city }} </p>
    <p class="irt-no-margin" *ngIf="userContent.country">   {{ userContent.country }} </p>
    <p class="irt-no-margin" *ngIf="userContent.province">  {{ userContent.province }} </p>
    <p class="irt-no-margin" *ngIf="userContent.zip_code">  {{ userContent.zip_code }} </p>

    <p class="irt-no-margin irt-primary-medium-color irt-margin-top" 
        *ngIf="isLicenseStored()"> 
        Patente conducente 
    </p>
    <hr style="opacity:0.3">
    <p class="irt-no-margin" *ngIf="userContent.license_num">   {{ userContent.license_num }} </p>
    <p class="irt-no-margin" *ngIf="userContent.license_place"> {{ userContent.license_place }} </p>
    <p class="irt-no-margin" *ngIf="userContent.license_date">  {{ userContent.license_date | date:'short' }} </p>
    <p class="irt-no-margin" *ngIf="userContent.license_exp">   {{ userContent.license_exp  | date:'short' }} </p>
  </div>
</div>
