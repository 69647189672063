export enum NavbarItemName {
  RESERVATIONS  = 'prenotazioni',
  VEHICLES      = 'veicoli',
  PLANNER       = 'planner',
  USERS         = 'utenti'
}

export enum PowerSupply {
  DIESEL    = 'diesel',
  FUEL      = 'benzina',
  HYBRID    = 'ibrido',
  ELECTRIC  = 'elettrico'
}

/** ADDRESSES **/
export const HEADQUARTER_ADDRESS    : string = 'Siracusa (SEDE C.SO UMBERTO I, 4)';
export const STATION_ADDRESS        : string = 'Siracusa (STAZIONE FERROVIARIA)';
export const AIRPORT_ADDRESS        : string = 'Catania (AEROPORTO DI CATANIA)';

/** DIALOGS **/
export const PASSWORD_RESET_SUCCESS_TITLE     : string = 'Password reimpostata';
export const PASSWORD_RESET_SUCCESS_MESSAGE   : string = 'Password reimpostata con successo!';
export const PASSWORD_MISMATCH_ERROR_MESSAGE  : string = 'Le due password non coincidono';