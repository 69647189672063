import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from '../../core/core.module';
import {RESERVATIONS_PATH, VehicleState} from '../../shared/constants';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {VehicleDetailRevisionsComponent} from '../vehicle-detail-revisions/vehicle-detail-revisions.component';
import {Vehicle, VehiclesService} from '../../core/vehicles.service';
import {VehicleDetailDamagesComponent} from '../vehicle-detail-damages/vehicle-detail-damages.component';
import {VehicleDetailRevisionsAddComponent} from '../vehicle-detail-revisions-add/vehicle-detail-revisions-add.component';
import {VehicleDetailDamagesAddComponent} from '../vehicle-detail-damages-add/vehicle-detail-damages-add.component';
import {AuthService} from '../../core/auth.service';
import {Router} from '@angular/router';
import { VehicleDetailStatusComponent } from '../vehicle-detail-status/vehicle-detail-status.component';

@Component({
  selector: 'app-vehicle-detail-section',
  templateUrl: './vehicle-detail-section.component.html',
  styleUrls: ['./vehicle-detail-section.component.scss']
})
export class VehicleDetailSectionComponent implements OnInit {
  @Input()  vehicle             : Resource;
  @Output() refresh             : EventEmitter<void>    = new EventEmitter<void>();
  @Output() updateVehicleClick  : EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  @Output() deleteVehicleClick  : EventEmitter<void>    = new EventEmitter<void>();
  isOnSale                      : boolean               = false;
  showMenuSpinner               : boolean = false;
  vehicleContent                : Vehicle;

  constructor(private dialogService: DialogService, private dialog: MatDialog,
              private vehiclesService: VehiclesService, private authService: AuthService, 
              private router: Router) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.isOnSale       = (this.vehicle.content as Vehicle).is_on_sale;
    this.vehicleContent = this.vehicle.content as Vehicle;
  }

  onRevisionsClick() {
    this.dialogService.openDialog(VehicleDetailRevisionsComponent, {
      revisions : (this.vehicle.content as Vehicle).revisions,
      vehicle   : this.vehicle
    }).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.add) {
          this.dialogService.openDialog(VehicleDetailRevisionsAddComponent, {vehicle: this.vehicle}).afterClosed().subscribe(
            dialogResult => {
              if (dialogResult && dialogResult.success) {
                this.refresh.emit();
              }
            }
          );
        }
      }
    );
  }

  onDamagesClick() {
    this.dialogService.openDialog(VehicleDetailDamagesComponent, {
      damages: (this.vehicle.content as Vehicle).damages,
      vehicle: this.vehicle
    }).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.add) {
          this.dialogService.openDialog(VehicleDetailDamagesAddComponent, {vehicle: this.vehicle}).afterClosed().subscribe(
            dialogResult => {
              if (dialogResult && dialogResult.success) {
                this.refresh.emit();
              }
            }
          );
        }
      }
    );
  }

  onCheckboxChange(event) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        (this.vehicle.content as Vehicle).is_on_sale = event.checked;
        this.vehiclesService.updateVehicle(token, this.vehicle).subscribe(
          response => {
            this.vehicle = response.data;
            this.isOnSale = (this.vehicle.content as Vehicle).is_on_sale;
          }, error => {
            this.router.navigate([RESERVATIONS_PATH]);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  onVehicleUpdateClick(vehicle: Vehicle) {
    this.updateVehicleClick.emit(vehicle);
  }

  onVehicleDeleteClick() {
    this.deleteVehicleClick.emit();
  }

  onStatusClick() {
    this.dialogService.openDialog(VehicleDetailStatusComponent, { status: this.vehicle.status }).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success && dialogResult.status) {
          this.onStatusForceClick(dialogResult.status);
        }
      }
    );
  }

  onStatusForceClick(status: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string                   = user.signInUserSession.idToken.jwtToken;
        this.vehicle.status                 = status;
        this.setVehicleStatus(token, status);
      }, error => {
        this.authService.signOut();
      }
    );
  }

  setVehicleStatus(token: string, status: string) {
    this.showMenuSpinner = true;
    this.vehiclesService.setStatus(this.vehicle, status).subscribe(
      response => {
        this.showMenuSpinner = false;
      }, error => {
        this.showMenuSpinner = false;
      }
    );
  }

  getStatusClass(vehicle: Resource) {
    return {
      'irt-vehicle-free-status'       : vehicle.status == VehicleState.FREE,
      'irt-vehicle-scheduled-status'  : vehicle.status == VehicleState.SCHEDULED,
      'irt-vehicle-busy-status'       : vehicle.status == VehicleState.BUSY,
      'irt-vehicle-hidden-status'     : vehicle.status == VehicleState.HIDDEN,
    };
  }
}
