import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { Resource } from 'src/app/core/core.module';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { UserDetailsDialogComponent } from 'src/app/user-details/user-details-dialog/user-details-dialog.component';
import { UserDetailsUpdateDialogComponent } from 'src/app/user-details/user-details-update-dialog/user-details-update-dialog.component';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {
  @Input()  _users          : BehaviorSubject<Resource[]>;
  @Output() deleteClick     : EventEmitter<Resource>    = new EventEmitter<Resource>();
  @Output() refreshClick    : EventEmitter<void>        = new EventEmitter<void>();
  @Output() reloadUsers     : EventEmitter<string>      = new EventEmitter<string>();
  @Output() filterUsers     : EventEmitter<void>        = new EventEmitter<void>();
  formGroup         : FormGroup;
  updateTime        : number;
  columnsToDisplay  : string[]  = [
    'id', 'user', 'company', 'vat', 'dest_code', 'email', 'phone', 'code', 'actions'
  ];
  dataSource = new MatTableDataSource<Resource>([]);

  constructor(private dialogService     : DialogService, 
              private dialog            : MatDialog, 
              private formService       : FormService) {
    this.formGroup            = this.formService.userSearchFormGroup;
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this._users.subscribe(
      users => {
        if (users) {
          this.dataSource.data  = users;
          this.updateTime       = new Date().getTime();
        }
      }
    );
  }

  onUserClick(user: Resource) {
    this.dialogService.openDialog(UserDetailsDialogComponent, {user: user});
  }

  onUserUpdateClick(user: Resource) {
    this.dialogService.openDialog(UserDetailsUpdateDialogComponent, {user: user}).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success) {
          this.onRefreshClick();
        }
      }
    );
  }

  onUserDeleteClick(user: Resource) {
    this.deleteClick.emit(user);
  }

  onRefreshClick() {
    this.refreshClick.emit();
  }

  onInputChange(event) {
    this.reloadUsers.emit(this.formGroup.get('user_search').value);
  }

  onFilterUsersClick() {
    this.filterUsers.emit();
  }
}
