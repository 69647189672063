<div id="irt-users-section-root-layout" class="irt-full-height">
  <div class="irt-margin-top irt-spaced-row">
    <h1 class="irt-no-margin"> Utenti </h1>
    <div class="irt-centered-row" style="margin-right: -12px;">
      <button class="irt-half-margin irt-users-section-selector-button"
              (click)="onCustomerTypeClick(privateCustomerType)" 
              [ngClass]="getCustomerSelectorClass(privateCustomerType)" mat-button>
        Privati
      </button>

      <button class="irt-half-margin irt-users-section-selector-button"
              (click)="onCustomerTypeClick(companyCustomerType)"  
              [ngClass]="getCustomerSelectorClass(companyCustomerType)" mat-button>
        Aziende
      </button>
    </div>
  </div>

  <app-users-table  [_users]="_users" (refreshClick)="onRefreshClick()"
                    (deleteClick)="onUserDeleteClick($event)"
                    (filterUsers)="onFilterUsersClick()"
                    (reloadUsers)="onCustomersReloaded($event)"
                    *ngIf="users && users.length>0"></app-users-table>
  <div class="irt-full-width irt-centered-row irt-margin-top"
       *ngIf="users && users.length>0 && currentPage < totalPages">
    <button mat-button class="irt-main-button" (click)="onLoadClick()">
      <span *ngIf="!showLoadButtonSpinner"> Carica altro </span>
      <div class="irt-spinner-layout" *ngIf="showLoadButtonSpinner">
        <mat-spinner [diameter]="20" color="accent"> </mat-spinner>
      </div>
    </button>
  </div>

  <div  class="irt-centered-column" 
        style="height: calc(100% - 104px)" 
        *ngIf="users && users.length<=0">
    <h3> Non ci sono utenti salvati </h3>
    <div class="irt-centered-row irt-wrapped-row">
      <button mat-button class="irt-secondary-button"
              (click)="onRefreshClick()" style="margin: 12px">
        <span> Ricarica utenti </span>
      </button>
    </div>
  </div>

  <div  class="irt-spinner-layout irt-full-width irt-centered-row"
        style="height: calc(100% - 104px)" 
        *ngIf="!users">
    <div  class="irt-padding irt-border-radius" 
          style="background: #F4F4F4;">
      <mat-spinner  [diameter]="30" 
                    color="accent"> </mat-spinner>
    </div>
  </div>
</div>
