import {Component, Inject, OnInit} from '@angular/core';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Reservation} from '../../core/reservations.service';
import {AIRPORT_ADDRESS, HEADQUARTER_ADDRESS, STATION_ADDRESS} from '../../shared/translations';
import {Customer, CustomersService, CustomerType} from '../../core/customers.service';
import {
  AIRPORT_ADDRESS_VALUE,
  HEADQUARTER_ADDRESS_VALUE,
  HOURS_OF_THE_DAY,
  MINUTES_OF_THE_HOUR,
  STATION_ADDRESS_VALUE
} from '../../shared/constants';

@Component({
  selector: 'app-reservations-builder-basics',
  templateUrl: './reservations-builder-basics.component.html',
  styleUrls: ['./reservations-builder-basics.component.scss']
})
export class ReservationsBuilderBasicsComponent implements OnInit {
  formGroup         : FormGroup;
  matcher           : ErrorStateMatcher;
  hours             : string[]            = HOURS_OF_THE_DAY;
  minutes           : string[]            = MINUTES_OF_THE_HOUR;
  places            : string[]            = [HEADQUARTER_ADDRESS, STATION_ADDRESS, AIRPORT_ADDRESS];
  placesValues      : string[]            = [HEADQUARTER_ADDRESS_VALUE, STATION_ADDRESS_VALUE, AIRPORT_ADDRESS_VALUE];
  privateView       : CustomerType        = CustomerType.PRIVATE;
  businessView      : CustomerType        = CustomerType.COMPANY;
  currentView       : CustomerType        = this.privateView;
  isEmailLoading    : boolean             = false;
  isBusinessLoading : boolean             = false;

  constructor(
    private formService       : FormService, 
    private customersService  : CustomersService,
    private dialogRef         : MatDialogRef<ReservationsBuilderBasicsComponent>
  ) {
    this.formGroup              = formService.reservationBasicsFormGroup;
    this.matcher                = formService.matcher;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.formGroup.reset();
    this.formGroup.get('type').setValue(this.currentView);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.validateForm()) {
      let reservation: Reservation  = this.buildReservation();
      let save: boolean             = this.formGroup.get('save').value && this.formGroup.get('save').value != '';
      this.dialogRef.close({
        success     : true,
        reservation : reservation,
        save        : save,
        type        : this.currentView
      });
    }
  }

  onTypeChange() {
    const type = this.formGroup.get('type').value;
    if (type) {
      this.currentView = type;
    }
  }

  onBusinessNameChange() {
    const businessName = this.formGroup.get('business_name').value;
    this.isBusinessLoading = true;
    this.customersService.findCustomers('', CustomerType.COMPANY, null, null, null, null, businessName).subscribe(
      response => {
        this.isBusinessLoading = false;
        if (response && response.data && response.data.data && response.data.data.length) {
          this.fillUser(response.data.data[0].content as Customer);
        }
      },
      error => {
        this.isBusinessLoading = false;
      }
    );
  }

  onEmailChange() {
    if (this.currentView == this.privateView) {
      const email         = this.formGroup.get('email').value;
      this.isEmailLoading = true;
      this.customersService.readCustomer(email).subscribe(
        response => {
          this.isEmailLoading = false;
          this.fillUser(response.data.content as Customer);
        },
        error => {
          this.isEmailLoading = false;
        }
      );
    }
  }

  buildReservation(): Reservation {
    let reservation: Reservation  = new Reservation();
    let user: Customer            = new Customer();

    if (this.currentView == this.businessView) {
      user.vat                    = this.formGroup.get('vat')           .value;
      user.business_name          = this.formGroup.get('business_name') .value;
      user.dest_code              = this.formGroup.get('dest_code')     .value;
    }
    user.first_name               = this.formGroup.get('first_name')    .value;
    user.last_name                = this.formGroup.get('last_name')     .value;
    user.email                    = this.formGroup.get('email')         .value;
    user.code                     = this.formGroup.get('code')          .value;
    user.address                  = this.formGroup.get('address')       .value;
    user.city                     = this.formGroup.get('city')          .value;
    user.country                  = this.formGroup.get('country')       .value;
    user.province                 = this.formGroup.get('province')      .value;
    user.zip_code                 = this.formGroup.get('zip_code')      .value;
    user.license_num              = this.formGroup.get('license_num')   .value;
    user.license_date             = this.formGroup.get('license_date')  .value;
    user.license_exp              = this.formGroup.get('license_exp')   .value;
    user.license_place            = this.formGroup.get('license_place') .value;
    user.type                     = this.currentView;

    const phonePrefix             = this.formGroup.get('phone_prefix').value;
    const phoneValue              = this.formGroup.get('phone').value;
    user.phone                    = {
      prefix  : phonePrefix,
      value   : phoneValue
    }

    reservation.customer = user;
    return reservation;
  }

  fillUser(user: Customer) {
    this.formGroup.get('vat')           .setValue(user.vat);
    this.formGroup.get('business_name') .setValue(user.business_name);
    this.formGroup.get('dest_code')     .setValue(user.dest_code);
    this.formGroup.get('first_name')    .setValue(user.first_name);
    this.formGroup.get('last_name')     .setValue(user.last_name);
    this.formGroup.get('email')         .setValue(user.email);
    this.formGroup.get('phone_prefix')  .setValue(user.phone.prefix);
    this.formGroup.get('phone')         .setValue(user.phone.value);
    this.formGroup.get('code')          .setValue(user.code);
    this.formGroup.get('address')       .setValue(user.address);
    this.formGroup.get('city')          .setValue(user.city);
    this.formGroup.get('country')       .setValue(user.country);
    this.formGroup.get('province')      .setValue(user.province);
    this.formGroup.get('zip_code')      .setValue(user.zip_code);
    this.formGroup.get('license_num')   .setValue(user.license_num);
    this.formGroup.get('license_date')  .setValue(user.license_date);
    this.formGroup.get('license_exp')   .setValue(user.license_exp);
    this.formGroup.get('license_place') .setValue(user.license_place);
  }

  validateForm() {
    return this.formService.validateForm(this.formGroup);
  }

  getButtonClass() {
    return {
      'irt-main-button'           : this.validateForm(),
      'irt-main-disabled-button'  : !this.validateForm()
    }
  }
}
