import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth.service';
import { ButtonService } from 'src/app/core/button.service';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import { PASSWORD_MISMATCH_ERROR_MESSAGE } from 'src/app/shared/translations';

@Component({
  selector: 'app-auth-forgot-pss-confirm-dialog',
  templateUrl: './auth-forgot-pss-confirm-dialog.component.html',
  styleUrls: ['./auth-forgot-pss-confirm-dialog.component.scss']
})
export class AuthForgotPssConfirmDialogComponent implements OnInit {
  formGroup         : FormGroup;
  email             : string;
  isPasswordVisible : boolean = false;
  showButtonSpinner : boolean = false;

  constructor(private dialogRef     : MatDialogRef<AuthForgotPssConfirmDialogComponent>, 
              private authService   : AuthService, 
              private formService   : FormService,
              private dialog        : MatDialog,
              private dialogService : DialogService,
              public  buttonService : ButtonService,
              @Inject(MAT_DIALOG_DATA) data 
  ) {
    this.formGroup            = this.formService.forgotPssConfirmFormGroup;
    this.email                = data.email;
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {}

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      const code            : string  = this.formGroup.get('code').value;
      const password        : string  = this.formGroup.get('password').value;
      const passwordConfirm : string  = this.formGroup.get('passwordConfirm').value;

      if (password == passwordConfirm) {
        this.showButtonSpinner          = true;
        this.authService.forgotPasswordSubmit(this.email, code, password).subscribe(
          response    => {
            this.showButtonSpinner = false;
            this.dialogRef.close({success: true});
          },
          error       => {
            this.showButtonSpinner = false;
            this.dialogRef.close({success: false, error: error.message});
          }
        );
      } else {
        this.dialogService.openErrorDialog(PASSWORD_MISMATCH_ERROR_MESSAGE);
      }
    }
  }
}
