import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { Resource } from './core.module';
import { environment } from '../../environments/environment';
import { Customer } from './customers.service';

export const RESERVATIONS_API_NAME: string = 'reservations';
export const RESERVATIONS_API_ENDPOINT: string =
  environment.API_BASE_URL + '/reservations';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  createReservation(token: string, reservation: Reservation, save?: boolean) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/';
    let init = {
      response: true,
      body: { content: reservation },
      queryStringParameters: {
        save_customer: save,
      },
    };
    return from(API.post(apiName, path, init));
  }

  readReservation(token: string, uri: string) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + uri;
    let init = { response: true };
    return from(API.get(apiName, path, init));
  }

  updateReservation(token: string, reservation: Resource) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + reservation.key.uri;
    let init = {
      response: true,
      body: { content: reservation.content },
    };
    return from(API.put(apiName, path, init));
  }

  deleteReservation(token: string, reservation: Resource) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + reservation.key.uri;
    let init = { response: true };
    return from(API.del(apiName, path, init));
  }

  findReservations(
    token: string,
    page?: number,
    email?: string,
    startDate?: number,
    endDate?: number,
    plate?: string
  ) {
    const RESERVATIONS_PER_PAGE: number = 30;
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/';
    let queryStringParams = {
      page: page ? page : 1,
      per_page: RESERVATIONS_PER_PAGE,
    };
    if (email) {
      queryStringParams['email'] = email;
    }
    if (startDate) {
      queryStringParams['start_date'] = startDate;
    }
    if (endDate) {
      queryStringParams['end_date'] = endDate;
    }
    if (plate) {
      queryStringParams['vehicle_plate'] = plate;
    }
    let init = {
      response: true,
      queryStringParameters: queryStringParams,
    };
    return from(API.get(apiName, path, init));
  }

  assignVehicle(
    reservation: Resource,
    vehicle: Resource,
    km?: number,
    services?: Service[],
    deductibles?: Deductible[],
    send_contract?: boolean
  ) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string =
      '/' + reservation.key.uri + '/vehicles/' + vehicle.key.uri;
    let queryParams = {
      send_contract: send_contract,
    };
    if (km) {
      queryParams['km'] = km;
    }
    const init = {
      response: true,
      body: {
        services,
        deductibles,
      },
      queryStringParameters: queryParams,
    };
    return from(API.put(apiName, path, init));
  }

  setStatus(reservation: Resource, status: string) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + reservation.key.uri + '/status/' + status;
    let init = { response: true };
    return from(API.patch(apiName, path, init));
  }

  endContract(reservation: Resource, km?: number) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + reservation.key.uri + '/send-end-contract';
    let init;
    if (km) {
      init = {
        response: true,
        queryStringParameters: {
          km: km,
        },
      };
    } else {
      init = { response: true };
    }
    return from(API.post(apiName, path, init));
  }
}

export class Reservation {
  customer: Customer;
  payment: {
    amount: number;
    payment_intent_client_secret: string;
  };
  start_address: string;
  start_date: number;
  end_address: string;
  end_date: number;
  vehicle_category: string;
  vehicle_brand: string;
  vehicle_model: string;
  vehicle_plate: string;
  contract_url: string;
  end_contract_url: string;
  services: Service[];
  deductibles: Deductible[];
  notes: string[];
}

export class Service {
  id: number;
  img_url: string;
  title: string;
  description: string;
  link: string;
  price: number;
}

export class Deductible {
  title: string;
  price: number;
}

export enum ServiceTitle {
  FULL_KASKO_TITLE = 'full_kasko',
  UNLIMITED_KM_TITLE = 'unlimited_km',
  LIMITED_KM_TITLE = 'limited_km',
  DAMAGE_DEDUCTIBLE_TITLE = 'damage_deductible',
  THEFT_DEDUCTIBLE_TITLE = 'theft_deductible',
}
