import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Storage} from 'aws-amplify';
import {Vehicle} from '../../core/vehicles.service';

@Component({
  selector: 'app-vehicles-builder-image',
  templateUrl: './vehicles-builder-image.component.html',
  styleUrls: ['./vehicles-builder-image.component.scss']
})
export class VehiclesBuilderImageComponent implements OnInit {
  image             : File;
  vehicle           : Vehicle;
  imageUrl          : string | ArrayBuffer;
  showImageSpinner  : boolean = false;

  constructor(private dialogRef: MatDialogRef<VehiclesBuilderImageComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.dialogRef.disableClose = true;
    this.vehicle                = data.vehicle;
  }

  ngOnInit() {
    if (this.vehicle && this.vehicle.img_url) {
      Storage.get(this.vehicle.img_url, {level: 'public'}).then(result => {
        this.imageUrl = result.toString();
      }).catch(err => {});
    }
  }

  onImageInput(event) {
    this.image = event.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(this.image);
    this.showImageSpinner = true;
    reader.onload = (_event) => {
      Storage.put('vehicle_img_' + this.vehicle.plate, this.image, {level: 'public', contentType: 'image/*'}).then(result => {
        this.vehicle.img_url  = (result as { key: string }).key;
        this.imageUrl         = reader.result;
        this.showImageSpinner = false;
      }).catch(err => {console.error(err); this.showImageSpinner = false;});
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    this.dialogRef.close({success: true, vehicle: this.vehicle});
  }

  getButtonClass() {
    return {
      'irt-main-button'           : this.imageUrl,
      'irt-main-disabled-button'  : !this.imageUrl
    }
  }
}
