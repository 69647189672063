import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource } from 'src/app/core/core.module';
import { Customer } from 'src/app/core/customers.service';

@Component({
  selector: 'app-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss']
})
export class UserDetailsDialogComponent implements OnInit {
  user        : Resource;
  userContent : Customer;

  constructor(private dialogRef: MatDialogRef<UserDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) { 
    this.user         = data.user;
    this.userContent  = this.user.content as Customer;
  }

  ngOnInit() { }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  isAddressStored() {
    return (
      this.userContent.address  || this.userContent.city || this.userContent.country ||
      this.userContent.province || this.userContent.zip_code
    );
  }

  isLicenseStored() {
    return (
      this.userContent.license_num  || this.userContent.license_place ||
      this.userContent.license_date || this.userContent.license_exp
    );
  }

  isCompanyStored() {
    return this.userContent.business_name || this.userContent.vat || this.userContent.dest_code;
  }
}
