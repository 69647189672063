import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { Resource } from 'src/app/core/core.module';
import { CustomersService, CustomerType } from 'src/app/core/customers.service';
import { DialogService } from 'src/app/core/dialog.service';
import { RESOURCE_NOT_FOUND_CODE } from 'src/app/shared/constants';
import { UsersFiltersComponent } from '../users-filters/users-filters.component';

@Component({
  selector: 'app-users-section',
  templateUrl: './users-section.component.html',
  styleUrls: ['./users-section.component.scss']
})
export class UsersSectionComponent implements OnInit {
  users                   : Resource[];
  _users                  : BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>(null);
  nextPage                : number        = 1;
  currentPage             : number        = 1;
  totalPages              : number        = 1000;
  showLoadButtonSpinner   : boolean       = false;
  privateCustomerType     : CustomerType  = CustomerType.PRIVATE;
  companyCustomerType     : CustomerType  = CustomerType.COMPANY;
  currentCustomerType     : CustomerType  = this.privateCustomerType;

  constructor(private customersService: CustomersService, private authService: AuthService,
              private dialogService: DialogService, private dialog: MatDialog) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.nextPage = 1;
    this.readCustomers();
  }

  onRefreshClick() {
    this.nextPage = 1;
    this.users    = null;
    this._users.next(this.users);
    this.readCustomers();
  }

  onLoadClick() {
    this.nextPage++;
    this.readCustomers();
  }

  onCustomersReloaded(searchText: string) {
    this.nextPage = 1;
    this.users    = null;
    this._users.next(this.users);
    this.readCustomers(searchText);
  }

  onCustomerTypeClick(type: CustomerType) {
    this.currentCustomerType = type;
    this.onRefreshClick();
  }

  onFilterUsersClick() {
    this.dialogService.openDialog(UsersFiltersComponent).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && (dialogResult.first_name || dialogResult.last_name)) {
          this.nextPage = 1;
          this.users    = null;
          this._users.next(this.users);
          this.readCustomers(null, dialogResult.first_name, dialogResult.last_name);
        }
      }
    );
  }

  onUserDeleteClick(user: Resource) {
    this.nextPage = 1;
    this.users    = null;
    this._users.next(this.users);
    this.customersService.deleteCustomer(user).subscribe(
      repsonse => {
        this.readCustomers();
      }, error => {
        console.error(error);
      }
    );
  }

  readCustomers(searchText?: string, firstName?: string, lastName?: string) {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.showLoadButtonSpinner = true;
        this.customersService.findCustomers(token, this.currentCustomerType, this.nextPage, searchText, firstName, lastName).subscribe(
          response => {
            if (this.nextPage == 1) {
              this.users = response.data.data;
              this._users.next(this.users);
            } else {
              this.users = this.users.concat(response.data.data);
              this._users.next(this.users);
            }
            this.currentPage = response.data.page;
            this.totalPages = response.data.total_pages;
            this.showLoadButtonSpinner = false;
          }, error => {
            if (error.response.data.error.code == RESOURCE_NOT_FOUND_CODE) {
              this.users = [];
              this._users.next(this.users);
            }
            this.showLoadButtonSpinner = false;
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  getCustomerSelectorClass(type: CustomerType) {
    return {
      'irt-main-button'       : type == this.currentCustomerType,
      'irt-secondary-button'  : type != this.currentCustomerType
    }
  }
}
