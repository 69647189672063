<div id="irt-vehicles-builder-image-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Aggiungi un'immagine 
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-vehicles-builder-image-content-layout" class="irt-centered-column">
    <div id="irt-vehicles-builder-image-input-layout"
         class="irt-primary-medium-background irt-centered-column irt-cursor-pointer"
         (click)="imageInput.click()" *ngIf="!imageUrl && !showImageSpinner">
      <mat-icon class="irt-primary-medium-color"> cloud_upload </mat-icon>
      <span> Carica </span>
      <input #imageInput type="file" (change)="onImageInput($event)" style="display:none;">
    </div>

    <div *ngIf="imageUrl && !showImageSpinner">
      <img class="irt-vehicles-builder-image irt-cursor-pointer" [src]="imageUrl">
      <div (click)="frontFileInput.click()"
           class="irt-cursor-pointer irt-centered-row irt-half-margin-top">
        <span> Modifica </span>
        <mat-icon class="irt-margin-left"> create </mat-icon>
        <input #frontFileInput type="file" (change)="onImageInput($event)" style="display:none;">
      </div>
    </div>

    <div class="irt-spinner-layout irt-full-width irt-full-height" *ngIf="showImageSpinner">
      <mat-spinner [diameter]="40" color="accent"> </mat-spinner>
    </div>

    <div class="irt-full-width irt-centered-row irt-margin-top">
      <button mat-button [ngClass]="getButtonClass()" [disabled]="!imageUrl" (click)="onConfirmClick()">
        <h3 class="irt-no-margin"> Conferma </h3>
      </button>
    </div>
  </div>
</div>
