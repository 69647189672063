<div id="irt-reservation-detail-start-contract-root-layout">
    <div  class="irt-full-width irt-spaced-row irt-padding"
          style="padding-bottom: 0;">
      <h3 class="irt-no-margin irt-primary-medium-color"> 
        Contratto iniziale
      </h3> 
      <button mat-icon-button (click)="onCancelClick()">
        <mat-icon> clear </mat-icon>
      </button>
    </div>

    <div id="irt-reservation-detail-start-contract-content-layout">
      <mat-slide-toggle 
        class="irt-margin-bottom"
        [(ngModel)]="send_contract"> Invia SMS </mat-slide-toggle>

      <p> <u> Aggiungi il chilometraggio di uscita </u> </p>
      <form class="irt-form irt-margin-bottom" [formGroup]="formGroup">
          <mat-form-field appearance="outline" class="irt-full-width">
            <mat-label> Chilometraggio di uscita </mat-label>
            <input matInput type="number" formControlName="km" min="0" required>
          </mat-form-field>
      </form>

      <p> <u> Ci sono servizi aggiuntivi? </u> </p>
      <form [formGroup]="servicesFormGroup">
        <div class="irt-wrapped-row irt-spaced-row irt-half-margin-top"
              style="align-items: center">
          <mat-checkbox (change)="onServiceAddClick($event, serviceTitles[0])">
            <p class="irt-no-margin"> Polizza Full Kasko </p>
          </mat-checkbox>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Prezzo </mat-label>
            <input matInput type="number" formControlName="full_kasko">
            <span matSuffix> € </span>
          </mat-form-field>
        </div>

        <div class="irt-wrapped-row irt-spaced-row irt-half-margin-top"
              style="align-items: center">
          <mat-checkbox (change)="onServiceAddClick($event, serviceTitles[1])">
            <p class="irt-no-margin"> Km illimitati </p>
          </mat-checkbox>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Prezzo </mat-label>
            <input matInput type="number" formControlName="unlimited_km">
            <span matSuffix> € </span>
          </mat-form-field>
        </div>

        <div class="irt-wrapped-row irt-spaced-row irt-half-margin-top"
              style="align-items: center">
          <mat-checkbox (change)="onServiceAddClick($event, serviceTitles[2])">
            <p class="irt-no-margin"> Km limitati </p>
          </mat-checkbox>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Km </mat-label>
            <input matInput type="number" formControlName="limited_km_quantity">
          </mat-form-field>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Prezzo </mat-label>
            <input matInput type="number" formControlName="limited_km">
            <span matSuffix> € </span>
          </mat-form-field>
        </div>

        <!--<div class="irt-wrapped-row irt-spaced-row irt-half-margin-top"
              style="align-items: center">
          <mat-checkbox (change)="onServiceAddClick($event, serviceTitles[3])">
            <p class="irt-no-margin"> Franchigia danni </p>
          </mat-checkbox>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Prezzo </mat-label>
            <input matInput type="number" formControlName="damage_deductible">
            <span matSuffix> € </span>
          </mat-form-field>
        </div>

        <div class="irt-wrapped-row irt-spaced-row irt-half-margin-top"
              style="align-items: center">
          <mat-checkbox (change)="onServiceAddClick($event, serviceTitles[4])">
            <p class="irt-no-margin"> Franchigia furto </p>
          </mat-checkbox>
          <mat-form-field appearance="outline" 
                          class="irt-third-width irt-no-padding-field irt-half-margin-left">
            <mat-label> Prezzo </mat-label>
            <input matInput type="number" formControlName="theft_deductible">
            <span matSuffix> € </span>
          </mat-form-field>
        </div>-->
      </form>

      <form [formGroup]="serviceFormGroup" 
              class="irt-margin-top irt-spaced-row irt-wrapped-row">
        <mat-form-field appearance="outline" class="irt-half-width">
          <mat-label> Nome servizio </mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline" 
                        class="irt-half-margin-left irt-third-width">
          <mat-label> Prezzo </mat-label>
          <input matInput type="number" formControlName="price">
          <span matSuffix> € </span>
        </mat-form-field>

        <button mat-mini-fab 
              (click)="onCustomServiceAddClick()"
              style="margin-top: -12px"> 
          <mat-icon> add </mat-icon> 
        </button>
      </form>

      <div class="irt-row irt-wrapped-row" 
            style="font-size: 12px;">
        <div style="padding: 4px"
              *ngFor="let service of customServices">
          <span class="irt-primary-medium-color"> {{ service.title }}; </span>
        </div>
      </div>

      <p> <u> Vuoi specificare delle franchigie? </u> </p>
      <form [formGroup]="deductibleFormGroup" 
              class="irt-margin-top irt-spaced-row irt-wrapped-row">
        <mat-form-field appearance="outline" class="irt-half-width">
          <mat-label> Nome franchigia </mat-label>
          <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline" 
                        class="irt-half-margin-left irt-third-width">
          <mat-label> Prezzo </mat-label>
          <input matInput type="number" formControlName="price">
          <span matSuffix> € </span>
        </mat-form-field>

        <button mat-mini-fab 
              (click)="onCustomDeductibleAddClick()"
              style="margin-top: -12px"> 
          <mat-icon> add </mat-icon> 
        </button>
      </form>

      <div class="irt-row irt-wrapped-row" 
            style="font-size: 12px;">
        <div style="padding: 4px"
              *ngFor="let deductible of customeDeductibles">
          <span class="irt-primary-medium-color"> {{ deductible.title }}; </span>
        </div>
      </div>

      <div class="irt-full-width irt-centered-row irt-margin-top">
        <button mat-button class="irt-main-button" (click)="onConfirmClick()">
          <h3 class="irt-no-margin"> Conferma </h3>
        </button>
      </div>
    </div>
</div>