import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/auth.service';
import { ButtonService } from 'src/app/core/button.service';
import { FormService } from 'src/app/core/form.service';

@Component({
  selector: 'app-auth-forgot-pss-dialog',
  templateUrl: './auth-forgot-pss-dialog.component.html',
  styleUrls: ['./auth-forgot-pss-dialog.component.scss']
})
export class AuthForgotPssDialogComponent implements OnInit {
  formGroup           : FormGroup;
  showButtonSpinner   : boolean = false;

  constructor(private dialogRef       : MatDialogRef<AuthForgotPssDialogComponent>, 
              private authService     : AuthService,
              public  buttonService   : ButtonService,
              private formService     : FormService,
              @Inject(MAT_DIALOG_DATA) data) { 
    this.formGroup = this.formService.forgotPssFormGroup;
  }

  ngOnInit() {}

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let email: string       = this.formGroup.get('email').value;
      this.showButtonSpinner  = true;
      this.authService.forgotPassword(email).subscribe(
        response  => {
          this.showButtonSpinner = false;
          this.dialogRef.close({success: true, email: email});
        },
        error     => {
          this.showButtonSpinner = false;
          this.dialogRef.close({success: false, error: error.message});
        }
      );
    }
  }
}
