/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { AuthRoutingModule }                from './auth-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';

/** Components **/
import { AuthComponent }                        from './auth.component';
import { AuthForgotPssDialogComponent }         from './auth-forgot-pss-dialog/auth-forgot-pss-dialog.component';
import { AuthForgotPssConfirmDialogComponent }  from './auth-forgot-pss-confirm-dialog/auth-forgot-pss-confirm-dialog.component';

@NgModule({
  declarations: [
    AuthComponent, 
    AuthForgotPssDialogComponent, 
    AuthForgotPssConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  entryComponents: [
    AuthForgotPssDialogComponent, 
    AuthForgotPssConfirmDialogComponent
  ]
})
export class AuthModule { }
