import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from '../../core/core.module';
import {Vehicle} from '../../core/vehicles.service';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {VehiclesBuilderBasicsComponent} from '../../vehicles/vehicles-builder-basics/vehicles-builder-basics.component';
import {VehiclesBuilderImageComponent} from '../../vehicles/vehicles-builder-image/vehicles-builder-image.component';

@Component({
  selector: 'app-vehicle-detail-general',
  templateUrl: './vehicle-detail-general.component.html',
  styleUrls: ['./vehicle-detail-general.component.scss']
})
export class VehicleDetailGeneralComponent implements OnInit {
  @Input()  vehicle             : Resource;
  @Output() updateVehicleClick  : EventEmitter<Vehicle> = new EventEmitter<Vehicle>();
  vehicleContent                : Vehicle;
  isOnSale                      : boolean = false;

  constructor(private dialogService: DialogService, private dialog: MatDialog) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.isOnSale = (this.vehicle.content as Vehicle).is_on_sale;
    this.vehicleContent = this.vehicle.content as Vehicle;
  }

  onPlateQrClick() {
    window.open('https://www.qrcode-monkey.com/it/#text', '_blank');
  }

  onVehicleUpdateClick() {
    this.dialogService.openDialog(VehiclesBuilderBasicsComponent, {vehicle: this.vehicle.content}).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success && dialogResult.vehicle) {
          let basicsVehicle = dialogResult.vehicle;
          this.dialogService.openDialog(VehiclesBuilderImageComponent, {vehicle: dialogResult.vehicle}).afterClosed().subscribe(
            dialogResult => {
              if (dialogResult && dialogResult.success && dialogResult.vehicle) {
                this.updateVehicleClick.emit(dialogResult.vehicle);
              } else {
                this.updateVehicleClick.emit(basicsVehicle);
              }
            }
          );
        }
      }
    );
  }
}
