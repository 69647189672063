import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NavbarItem, RESERVATIONS_PATH} from '../shared/constants';
import {NavbarItemName} from '../shared/translations';
import {ReservationsService} from '../core/reservations.service';
import {Resource} from '../core/core.module';
import {AuthService} from '../core/auth.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.scss']
})
export class ReservationDetailComponent implements OnInit {
  reservationUri: string;
  navbarItem: NavbarItem;
  _reservation: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(null);

  constructor(
    private authService: AuthService, 
    private route: ActivatedRoute,
    private reservationsService: ReservationsService, 
    private router: Router
  ) {
    this.reservationUri   = this.route.snapshot.paramMap.get('reservation_uri');
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.RESERVATIONS;
  }

  ngOnInit() {
    if (this.reservationUri) {
      this.readReservation();
    } else {
      this.router.navigate([RESERVATIONS_PATH]);
    }
  }

  onRefreshClick(stop_read?: boolean) {
    if (this.reservationUri && !stop_read) {
      this._reservation.next(null);
      this.readReservation();
    } else if (stop_read) {
      this._reservation.next(null);
    } else {
      this.router.navigate([RESERVATIONS_PATH]);
    }
  }

  onReservationDeleteClick() {
    this.deleteReservation();
  }

  readReservation() {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.reservationsService.readReservation(token, this.reservationUri).subscribe(
          response => {
            this._reservation.next(response.data);
          }, error => {
            this.router.navigate([RESERVATIONS_PATH]);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }

  deleteReservation() {
    let reservation: Resource = this._reservation.getValue();
    this._reservation.next(null);
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        let token: string = user.signInUserSession.idToken.jwtToken;
        this.reservationsService.deleteReservation(token, reservation).subscribe(
          response => {
            this.router.navigate([RESERVATIONS_PATH]);
          }, error => {
            console.error(error);
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }
}
