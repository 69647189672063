import {Component, OnInit} from '@angular/core';
import {Resource} from '../../core/core.module';
import {VehiclesService} from '../../core/vehicles.service';
import {AuthService} from '../../core/auth.service';
import {DialogService} from '../../core/dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {FormService} from '../../core/form.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-planner-section',
  templateUrl: './planner-section.component.html',
  styleUrls: ['./planner-section.component.scss']
})
export class PlannerSectionComponent implements OnInit {
  formGroup             : FormGroup;
  vehicles              : Resource[];
  showLoadButtonSpinner : boolean = false;

  constructor(private vehiclesService: VehiclesService, private authService: AuthService,
              private dialogService: DialogService, private dialog: MatDialog, 
              private formService: FormService) {
    this.dialogService.dialog   = this.dialog;
    this.formGroup              = formService.plannerFiltersFormGroup;
  }

  ngOnInit() {}

  onSearchClick() {
    if (this.formService.validateForm(this.formGroup)) {
      this.readVehicles();
    }
  }

  readVehicles() {
    this.authService.getAuthenticatedUser().subscribe(
      user => {
        this.showLoadButtonSpinner = true;
        let startDate   : Date = new Date(this.formGroup.get('start_date').value);
        let endDate     : Date = new Date(this.formGroup.get('end_date').value);
        let startHour   : string = this.formGroup.get('start_hour').value;
        let endHour     : string = this.formGroup.get('end_hour').value;
        startDate.setHours(
          +startHour.substr(0, startHour.indexOf(':')),
          +startHour.substr(startHour.indexOf(':')+1, startHour.length)
        );
        endDate.setHours(
          +endHour.substr(0, endHour.indexOf(':')),
          +endHour.substr(endHour.indexOf(':')+1, endHour.length)
        );
        this.vehiclesService.listAvailableVehicles(startDate.getTime(), endDate.getTime())
            .subscribe(response => {
            this.vehicles               = response.data;
            this.showLoadButtonSpinner  = false;
          }, error => {
            this.showLoadButtonSpinner  = false;
          }
        );
      }, error => {
        this.authService.signOut();
      }
    );
  }
}
