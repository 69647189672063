import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-detail-qr-generator',
  templateUrl: './vehicle-detail-qr-generator.component.html',
  styleUrls: ['./vehicle-detail-qr-generator.component.scss']
})
export class VehicleDetailQrGeneratorComponent implements OnInit {
  elementType : 'url' | 'canvas' | 'img' = 'url';
  value       : string;

  constructor(private dialogRef: MatDialogRef<VehicleDetailQrGeneratorComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.value = data.value;
  }

  ngOnInit() {}

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    this.dialogRef.close();
  }
}
