<div id="irt-vehicle-detail-status-root-layout">
    <div  class="irt-full-width irt-spaced-row irt-padding"
          style="padding-bottom: 0;">
      <h3 class="irt-no-margin irt-primary-medium-color"> 
        Modifica stato
      </h3> 
      <button mat-icon-button (click)="onCancelClick()">
        <mat-icon> clear </mat-icon>
      </button>
    </div>
  
    <div id="irt-vehicle-detail-status-content-layout">
        <p> Imposta lo stato del veicolo </p>
        <form class="irt-form irt-margin-bottom" [formGroup]="formGroup">
            <mat-form-field appearance="outline" class="irt-full-width">
              <mat-select formControlName="status">
                  <mat-option *ngFor="let status of states" [value]="status"> {{ status }} </mat-option>
              </mat-select>
            </mat-form-field>
    
            <div class="irt-full-width irt-centered-row">
                <button mat-button class="irt-main-button" (click)="onConfirmClick()">
                  <h3 class="irt-no-margin"> Conferma </h3>
                </button>
            </div>
        </form>
    </div>
</div>
  