import {Component} from '@angular/core';
import {NavbarItem} from '../shared/constants';
import {NavbarItemName} from '../shared/translations';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent {
  navbarItem: NavbarItem;

  constructor() {
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.RESERVATIONS;
  }
}
