import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource } from 'src/app/core/core.module';
import { Customer, CustomersService, CustomerType } from 'src/app/core/customers.service';
import { FormService } from 'src/app/core/form.service';
import { Reservation } from 'src/app/core/reservations.service';

@Component({
  selector: 'app-user-details-update-dialog',
  templateUrl: './user-details-update-dialog.component.html',
  styleUrls: ['./user-details-update-dialog.component.scss']
})
export class UserDetailsUpdateDialogComponent implements OnInit {
  userResource      : Resource;
  user              : Customer;
  formGroup         : FormGroup;
  matcher           : ErrorStateMatcher;
  privateView       : CustomerType        = CustomerType.PRIVATE;
  businessView      : CustomerType        = CustomerType.COMPANY;
  currentView       : CustomerType        = this.privateView;
  showButtonSpinner : boolean             = false;

  constructor(private formService       : FormService, 
              private customersService  : CustomersService,
              private dialogRef         : MatDialogRef<UserDetailsUpdateDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data
  ) {
    this.formGroup              = formService.reservationBasicsFormGroup;
    this.matcher                = formService.matcher;
    this.userResource           = data.user;
    this.user                   = this.userResource.content as Customer;
  }

  ngOnInit() {
    this.formGroup.reset();
    this.formGroup.get('type').setValue(this.currentView);
    this.fillUser(this.user);
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.validateForm()) {
      const user: Customer      = this.buildUser();
      this.userResource.content = user;
      this.showButtonSpinner    = true;
      this.customersService.updateCustomer(this.userResource).subscribe(
        response => {
          this.showButtonSpinner = false;
          this.dialogRef.close({ success: true });
        }, error => {
          this.showButtonSpinner = false;
          console.error(error);
        }
      );
    }
  }

  onTypeChange() {
    const type = this.formGroup.get('type').value;
    if (type) {
      this.currentView = type;
    }
  }

  buildUser(): Customer {
    let user: Customer = new Customer();
    if (this.currentView == this.businessView) {
      user.vat                    = this.formGroup.get('vat')           .value;
      user.business_name          = this.formGroup.get('business_name') .value;
      user.dest_code              = this.formGroup.get('dest_code')     .value;
    }
    user.first_name               = this.formGroup.get('first_name')    .value;
    user.last_name                = this.formGroup.get('last_name')     .value;
    user.email                    = this.formGroup.get('email')         .value;
    user.code                     = this.formGroup.get('code')          .value;
    user.address                  = this.formGroup.get('address')       .value;
    user.city                     = this.formGroup.get('city')          .value;
    user.country                  = this.formGroup.get('country')       .value;
    user.province                 = this.formGroup.get('province')      .value;
    user.zip_code                 = this.formGroup.get('zip_code')      .value;
    user.license_num              = this.formGroup.get('license_num')   .value;
    user.license_date             = this.formGroup.get('license_date')  .value;
    user.license_exp              = this.formGroup.get('license_exp')   .value;
    user.license_place            = this.formGroup.get('license_place') .value;
    user.type                     = this.currentView;
    const phonePrefix             = this.formGroup.get('phone_prefix').value;
    const phoneValue              = this.formGroup.get('phone').value;
    user.phone                    = {
      prefix  : phonePrefix,
      value   : phoneValue
    }
    return user;
  }

  fillUser(user: Customer) {
    this.formGroup.get('vat')           .setValue(user.vat);
    this.formGroup.get('business_name') .setValue(user.business_name);
    this.formGroup.get('dest_code')     .setValue(user.dest_code);
    this.formGroup.get('first_name')    .setValue(user.first_name);
    this.formGroup.get('last_name')     .setValue(user.last_name);
    this.formGroup.get('email')         .setValue(user.email);
    this.formGroup.get('phone_prefix')  .setValue(user.phone.prefix);
    this.formGroup.get('phone')         .setValue(user.phone.value);
    this.formGroup.get('code')          .setValue(user.code);
    this.formGroup.get('address')       .setValue(user.address);
    this.formGroup.get('city')          .setValue(user.city);
    this.formGroup.get('country')       .setValue(user.country);
    this.formGroup.get('province')      .setValue(user.province);
    this.formGroup.get('zip_code')      .setValue(user.zip_code);
    this.formGroup.get('license_num')   .setValue(user.license_num);
    this.formGroup.get('license_date')  .setValue(user.license_date);
    this.formGroup.get('license_exp')   .setValue(user.license_exp);
    this.formGroup.get('license_place') .setValue(user.license_place);
  }

  validateForm() {
    return this.formService.validateForm(this.formGroup);
  }

  getButtonClass() {
    return {
      'irt-main-button'           : this.validateForm(),
      'irt-main-disabled-button'  : !this.validateForm()
    }
  }

}
