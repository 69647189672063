<div class="irt-spaced-row irt-margin-top">
  <div class="irt-centered-row">
    <button mat-icon-button (click)="onRefreshClick()" matTooltip="Ricarica utenti"> 
      <mat-icon> refresh </mat-icon> 
    </button>
    <p class="irt-half-margin-left" id="irt-users-update-layout"> 
      Aggiornato il {{ updateTime | date: 'short' }} 
    </p>
  </div>

  <form [formGroup]="formGroup">
    <button mat-icon-button (click)="onFilterUsersClick()"> <mat-icon> tune </mat-icon> </button>
    <mat-form-field class="irt-half-margin-left">
      <input  (change)="onInputChange($event.target)" matInput type="text" 
              placeholder="Cerca per email..." formControlName="user_search">
    </mat-form-field>
  </form>
</div>

<div class="irt-table-container" style="overflow-x: scroll;">
  <table class="irt-full-width"
        mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef class="irt-border-top-left-radius">
        <h2 class="irt-centered-text irt-ellipsized-text"> ID </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text"> {{ user.key.uri.substring(0,8) }} </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Email </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text" *ngIf="user.content && user.content.email">
          {{ user.content.email }} 
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.email"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Cliente </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text irt-ellipsized-text" *ngIf="user.content && user.content.first_name">
          <span> {{ user.content.first_name }} </span> 
          <span *ngIf="user.content.last_name"> {{ user.content.last_name }} </span>
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.first_name"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Azienda </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text irt-ellipsized-text" *ngIf="user.content && user.content.business_name">
          {{ user.content.business_name }}
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.business_name"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="vat">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> P. IVA </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text irt-ellipsized-text" *ngIf="user.content && user.content.vat">
          {{ user.content.vat }}
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.vat"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="dest_code">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Codice destinatario </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text irt-ellipsized-text" *ngIf="user.content && user.content.dest_code">
          {{ user.content.dest_code }}
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.dest_code"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Telefono </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p  class="irt-centered-text irt-ellipsized-text" 
            *ngIf="user.content && user.content.phone && user.content.phone.value">
          +
          {{ user.content.phone.prefix }} 
          {{ user.content.phone.value }}
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.phone || !user.content.phone.value"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Codice fiscale </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text" *ngIf="user.content && user.content.code">
          {{ user.content.code }} 
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.code"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>
        <h2 class="irt-centered-text irt-ellipsized-text"> Indirizzo </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <p class="irt-centered-text irt-ellipsized-text" *ngIf="user.content && user.content.address">
          {{ user.content.address }} 
          <span *ngIf="user.content.city">      ,{{ user.content.city }}    </span>
          <span *ngIf="user.content.country">   ,{{ user.content.country }} </span>
        </p>
        <p class="irt-centered-text" *ngIf="!user.content || !user.content.address"> - </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="irt-border-top-right-radius">
        <h2 class="irt-centered-text irt-ellipsized-text"> Azioni </h2>
      </th>

      <td mat-cell *matCellDef="let user">
        <div class="irt-centered-row">
          <button mat-icon-button 
                  class="irt-half-margin-right" 
                  (click)="onUserUpdateClick(user); $event.stopPropagation();"> 
            <mat-icon> create </mat-icon> 
          </button>

          <button mat-icon-button 
                  class="irt-half-margin-right" 
                  (click)="onUserDeleteClick(user); $event.stopPropagation()">  
            <mat-icon color="warn"> delete </mat-icon> 
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="irt-cursor-pointer" (click)="onUserClick(rowData)"
        mat-row *matRowDef="let rowData; columns: columnsToDisplay"></tr>
  </table>
</div>
