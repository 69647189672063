<div class="irt-table-container" style="overflow-x: scroll;">
  <table class="irt-full-width"
        mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="plate">
    <th mat-header-cell *matHeaderCellDef class="irt-border-top-left-radius">
      <h2 class="irt-centered-text irt-ellipsized-text"> Targa </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.plate">
        {{ vehicle.content.plate }} </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.plate"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Categoria </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.category">
        {{ vehicle.content.category }} </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.category"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="brand">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Produttore </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.brand">
        {{ vehicle.content.brand }} </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.brand"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Modello </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.model">
        {{ vehicle.content.model }} </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.model"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="year">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Anno </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.year">
        {{ vehicle.content.year }} </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.year"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="sales">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> In vendita </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.is_on_sale"> SI </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.is_on_sale"> NO </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="sales_link">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Link di vendita </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.link"> 
        {{ vehicle.content.link }}
      </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.link"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="supply">
    <th mat-header-cell *matHeaderCellDef>
      <h2 class="irt-centered-text irt-ellipsized-text"> Alimentazione </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <p class="irt-centered-text" *ngIf="vehicle.content && vehicle.content.supply"> 
        {{ vehicle.content.supply }}
      </p>
      <p class="irt-centered-text" *ngIf="!vehicle.content || !vehicle.content.supply"> - </p>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="irt-border-top-right-radius">
      <h2 class="irt-centered-text"> Stato </h2>
    </th>

    <td mat-cell *matCellDef="let vehicle">
      <div   class="irt-vehicles-table-status-layout"
            [ngClass]="getStatusClass(vehicle)" *ngIf="vehicle.status">
          <p class="irt-centered-text irt-no-margin"> {{ vehicle.status | lowercase }} </p>
        </div>
      <p class="irt-centered-text" *ngIf="!vehicle.status"> - </p>
    </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr class="irt-cursor-pointer" (click)="onVehicleClick(rowData)"
        mat-row *matRowDef="let rowData; columns: columnsToDisplay"></tr>
  </table>
</div>
