<h3 class="irt-primary-medium-color irt-no-margin irt-half-margin-bottom"> Seleziona il periodo da cercare </h3>
<form [formGroup]="formGroup" class="irt-full-width irt-spaced-row irt-wrapped-row">
  <div class="irt-planner-filters-date-layout irt-half-width irt-spaced-row">
    <mat-form-field appearance="outline" class="irt-half-width">
      <mat-label> Data inizio </mat-label>
      <input matInput type="text" required formControlName="start_date" [matDatepicker]="startDatePicker">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-quarter-width">
      <mat-label> Ora inizio </mat-label>
      <mat-select formControlName="start_hour" required>
        <mat-option *ngFor="let hour of hours" [value]="hour"> {{ hour }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-quarter-width">
      <mat-label> Minuti inizio </mat-label>
      <mat-select formControlName="start_minute" required>
        <mat-option *ngFor="let minute of minutes" [value]="minute"> {{ minute }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="irt-planner-filters-date-layout irt-half-width irt-spaced-row">
    <mat-form-field appearance="outline" class="irt-half-width irt-reservation-builder-basics-field">
      <mat-label> Data fine </mat-label>
      <input matInput type="text" required formControlName="end_date" [matDatepicker]="endDatePicker">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-quarter-width irt-reservation-builder-basics-field">
      <mat-label> Ora fine </mat-label>
      <mat-select formControlName="end_hour" required>
        <mat-option *ngFor="let hour of hours" [value]="hour"> {{ hour }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="irt-quarter-width irt-reservation-builder-basics-field">
      <mat-label> Minuti fine </mat-label>
      <mat-select formControlName="end_minute" required>
        <mat-option *ngFor="let minute of minutes" [value]="minute"> {{ minute }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="irt-full-width irt-end-row">
    <button id="irt-planner-filters-actions-button" 
            (click)="onSearchClick()" 
            style="min-width: 240px;"
            mat-button class="irt-main-button"> 
      <span> Cerca </span> 
    </button>
  </div>
</form>
