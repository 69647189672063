/** Modules **/
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { VehicleDetailRoutingModule }       from './vehicle-detail-routing.module';
import { SharedModule }                     from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

/** Material **/
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatInputModule }           from '@angular/material/input';
import { MatButtonModule }          from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule }            from '@angular/material/icon';
import { MatNativeDateModule }      from '@angular/material/core';
import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatTooltipModule }         from '@angular/material/tooltip';
import { MatCheckboxModule }        from '@angular/material/checkbox';
import { MatSelectModule }          from '@angular/material/select';

import { VehicleDetailComponent }             from './vehicle-detail.component';
import { VehicleDetailSectionComponent }      from './vehicle-detail-section/vehicle-detail-section.component';
import { VehicleDetailGalleryComponent }      from './vehicle-detail-gallery/vehicle-detail-gallery.component';
import { VehicleDetailGeneralComponent }      from './vehicle-detail-general/vehicle-detail-general.component';
import { VehicleDetailRevisionsComponent }    from './vehicle-detail-revisions/vehicle-detail-revisions.component';
import { VehicleDetailDamagesComponent }      from './vehicle-detail-damages/vehicle-detail-damages.component';
import { VehicleDetailRevisionsAddComponent } from './vehicle-detail-revisions-add/vehicle-detail-revisions-add.component';
import { VehicleDetailDamagesAddComponent }   from './vehicle-detail-damages-add/vehicle-detail-damages-add.component';
import { VehicleDetailQrGeneratorComponent }  from './vehicle-detail-qr-generator/vehicle-detail-qr-generator.component';
import { VehicleDetailStatusComponent } from './vehicle-detail-status/vehicle-detail-status.component';

@NgModule({
  declarations: [
    VehicleDetailComponent,
    VehicleDetailSectionComponent,
    VehicleDetailGalleryComponent,
    VehicleDetailGeneralComponent,
    VehicleDetailRevisionsComponent,
    VehicleDetailDamagesComponent,
    VehicleDetailRevisionsAddComponent,
    VehicleDetailDamagesAddComponent,
    VehicleDetailQrGeneratorComponent,
    VehicleDetailStatusComponent
  ],
  imports: [
    CommonModule,
    VehicleDetailRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSelectModule
  ],
  entryComponents: [
    VehicleDetailRevisionsComponent,
    VehicleDetailDamagesComponent,
    VehicleDetailRevisionsAddComponent,
    VehicleDetailDamagesAddComponent,
    VehicleDetailQrGeneratorComponent,
    VehicleDetailStatusComponent
  ]
})
export class VehicleDetailModule { }
