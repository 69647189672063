<div id="irt-vehicle-detail-revisions-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Manutenzioni
    </h3> 
    <button mat-icon-button (click)="onDialogCloseClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div class="irt-vehicle-detail-revisions-content-layout irt-full-width" *ngIf="revisions && revisions.length>0">
    <div class="irt-half-margin-bottom irt-primary-medium-background irt-padding irt-border-radius"
         *ngFor="let revision of revisions">
      <div class="irt-spaced-row irt-full-width">
        <p class="irt-no-margin"> {{ revision.date | date: 'short' }} </p>

        <div class="irt-spaced-row">
          <p class="irt-no-margin"> {{ revision.km }}km </p>
          <button mat-icon-button (click)="onRevisionDeleteClick(revision)"> 
            <mat-icon class="mat-warn"> delete </mat-icon> 
          </button>
        </div>
      </div>
      <p class="irt-no-margin irt-half-margin-top"> {{ revision.note }} </p>
    </div>
  </div>

  <div class="irt-vehicle-detail-revisions-content-layout irt-full-width" *ngIf="!revisions || revisions.length==0">
    <p> Non ci sono manutenzioni salvate. Aggiungi una manutenzione premendo il bottone in basso. </p>
  </div>

  <div class="irt-full-width irt-centered-row irt-double-margin-bottom">
    <button class="irt-main-button" mat-button (click)="onDialogConfirmClick()">
      <span> Aggiungi manutenzione </span>
    </button>
  </div>
</div>
