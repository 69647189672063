<div id="irt-vehicle-detail-section-root-layout" class="irt-full-height">
  <div id="irt-vehicle-detail-section-header-layout" class="irt-full-width irt-spaced-row">
    <h1 class="irt-no-margin irt-ellipsized-text"> {{ vehicleContent.plate }} </h1>
    <div class="irt-centered-row">
      <div  id="irt-vehicle-detail-table-status-layout" 
            class="irt-cursor-pointer"
            (click)="onStatusClick()"
            [ngClass]="getStatusClass(vehicle)">
        <h3 id="irt-vehicle-detail-table-status-text" class="irt-no-margin"> {{ vehicle.status }} </h3>
      </div>
      <button class="irt-half-margin-left" 
              matTooltip="Lista manutenzioni"
              mat-icon-button 
              (click)="onRevisionsClick()"> 
        <mat-icon> assignment </mat-icon> 
      </button>
      <button class="irt-half-margin-left"
              matTooltip="Lista danni"
              mat-icon-button 
              (click)="onDamagesClick()"> 
        <mat-icon> bolt </mat-icon> 
      </button>
      <button class="irt-half-margin-left"
              mat-icon-button 
              (click)="onVehicleDeleteClick()" 
              matTooltip="Elimina veicolo"> 
        <mat-icon color="warn"> delete </mat-icon> 
      </button>
    </div>
  </div>

  <div class="irt-centered-row irt-half-margin-top" style="justify-content: flex-start">
    <mat-checkbox class="irt-checkbox-layout" [checked]="isOnSale"
                  (change)="onCheckboxChange($event)"></mat-checkbox>
    <h3 class="irt-no-margin irt-half-margin-left irt-primary-medium-color"> Veicolo in vendita? </h3>
  </div>

  <div id="irt-vehicle-detail-section-content-layout" class="irt-full-height irt-margin-top">
    <div class="irt-three-third-width irt-full-height irt-primary-medium-background
                irt-vehicle-detail-section-content-layout irt-centered-row" style="padding: 0">
      <app-vehicle-detail-gallery class="irt-full-height irt-full-width"
                                  [vehicle]="vehicle"></app-vehicle-detail-gallery>
    </div>

    <div class="irt-third-width irt-full-height irt-primary-medium-background
                irt-vehicle-detail-section-content-layout">
      <app-vehicle-detail-general [vehicle]="vehicle"
                                  (updateVehicleClick)="onVehicleUpdateClick($event)"></app-vehicle-detail-general>
    </div>
  </div>
</div>
