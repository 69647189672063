import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormService} from '../../core/form.service';
import {Vehicle} from '../../core/vehicles.service';
import {PowerSupply} from '../../shared/translations';

@Component({
  selector: 'app-vehicles-builder-basics',
  templateUrl: './vehicles-builder-basics.component.html',
  styleUrls: ['./vehicles-builder-basics.component.scss']
})
export class VehiclesBuilderBasicsComponent implements OnInit {
  formGroup : FormGroup;
  matcher   : ErrorStateMatcher;
  vehicle   : Vehicle;
  supplies  : PowerSupply[] = [PowerSupply.DIESEL, PowerSupply.FUEL, PowerSupply.HYBRID];

  constructor(private formService: FormService,
              private dialogRef: MatDialogRef<VehiclesBuilderBasicsComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.formGroup              = formService.vehicleBasicsFormGroup;
    this.matcher                = formService.matcher;
    this.dialogRef.disableClose = true;
    if (data && data.vehicle) {
      this.vehicle = data.vehicle;
    }
  }

  ngOnInit() {
    this.formGroup.reset();
    if (this.vehicle) {
      this.formGroup.get('plate')     .setValue(this.vehicle.plate);
      this.formGroup.get('category')  .setValue(this.vehicle.category);
      this.formGroup.get('brand')     .setValue(this.vehicle.brand);
      this.formGroup.get('model')     .setValue(this.vehicle.model);
      this.formGroup.get('year')      .setValue(this.vehicle.year);
      this.formGroup.get('supply')    .setValue(this.vehicle.supply);
      this.formGroup.get('price')     .setValue(this.vehicle.price);
      this.formGroup.get('link')      .setValue(this.vehicle.link);
      this.formGroup.get('km')        .setValue(this.vehicle.km);
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onConfirmClick() {
    if (this.validateForm()) {
      let vehicle: Vehicle = this.buildVehicle();
      this.dialogRef.close({success: true, vehicle: vehicle});
    }
  }

  buildVehicle(): Vehicle {
    let vehicle: Vehicle  = new Vehicle();
    vehicle.plate         = this.formGroup.get('plate').value;
    vehicle.category      = this.formGroup.get('category').value;
    vehicle.brand         = this.formGroup.get('brand').value;
    vehicle.model         = this.formGroup.get('model').value;
    vehicle.year          = this.formGroup.get('year').value;
    vehicle.supply        = this.formGroup.get('supply').value;
    vehicle.price         = this.formGroup.get('price').value;
    vehicle.link          = this.formGroup.get('link').value;
    vehicle.km            = this.formGroup.get('km').value;
    if (this.vehicle) {
      vehicle.img_url = this.vehicle.img_url;
    }
    return vehicle;
  }

  validateForm() {
    return this.formService.validateForm(this.formGroup);
  }

  getButtonClass() {
    return {
      'irt-main-button'           : this.validateForm(),
      'irt-main-disabled-button'  : !this.validateForm()
    }
  }
}
