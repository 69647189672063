/** Modules **/
import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';

/** Material **/
import { MatIconModule }    from '@angular/material/icon';
import { MatButtonModule }  from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

/** Components **/
import { ToolbarComponent }     from './toolbar/toolbar.component';
import { NavbarComponent }      from './navbar/navbar.component';
import { InfoDialogComponent }  from './info-dialog/info-dialog.component';

@NgModule({
  declarations: [
    ToolbarComponent, 
    NavbarComponent, 
    InfoDialogComponent
  ],
  exports: [
    NavbarComponent,
    ToolbarComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule
  ],
  entryComponents: [InfoDialogComponent]
})
export class SharedModule { }
