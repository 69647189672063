import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {VEHICLE_DETAIL_PATH} from '../shared/constants';
import {VehicleDetailComponent} from './vehicle-detail.component';

const routes: Routes = [
  {
    path: VEHICLE_DETAIL_PATH + '/:vehicle_uri',
    component: VehicleDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehicleDetailRoutingModule { }
