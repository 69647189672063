import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from '../../core/core.module';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Reservation, ReservationsService} from '../../core/reservations.service';
import {AIRPORT_ADDRESS_VALUE, HEADQUARTER_ADDRESS_VALUE, ReservationState, STATION_ADDRESS_VALUE} from '../../shared/constants';
import { AIRPORT_ADDRESS, HEADQUARTER_ADDRESS, STATION_ADDRESS } from 'src/app/shared/translations';
import { DialogService } from 'src/app/core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ReservationsBuilderTimeComponent } from 'src/app/reservations/reservations-builder-time/reservations-builder-time.component';

@Component({
  selector: 'app-reservation-detail-general',
  templateUrl: './reservation-detail-general.component.html',
  styleUrls: ['./reservation-detail-general.component.scss']
})
export class ReservationDetailGeneralComponent implements OnInit {
  @Input()  _reservation        : BehaviorSubject<Resource>;
  @Output() endContractClick    : EventEmitter<void>  = new EventEmitter<void>();
  @Output() generalUpdate       : EventEmitter<void>  = new EventEmitter<void>();
  @Output() generalUpdateStart  : EventEmitter<void>  = new EventEmitter<void>();
  reservationResource         : Resource;
  reservation                 : Reservation;

  constructor(
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private reservation_service: ReservationsService
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit() {
    this._reservation.subscribe(
      reservation => {
        if (reservation) {
          this.reservationResource  = reservation;
          this.reservation          = reservation.content as Reservation;
        }
      }
    );
  }

  onEndContractClick() {
    this.endContractClick.emit();
  }

  onDetailsUpdateClick() {
    this.dialog_service.openDialog(
      ReservationsBuilderTimeComponent, 
      { reservation: this.reservation }
    ).afterClosed().subscribe(
      dialogResult => {
        if (dialogResult && dialogResult.success) {
          this.generalUpdateStart.emit();
          const reservation: Resource = this.reservationResource;
          reservation.content = dialogResult.reservation;
          this.reservation_service.updateReservation('', reservation).subscribe({
            next: (response) => {
              window.location.reload();
            },
            error: (error) => {
              this.dialog_service.openErrorDialog('Errore nell\'aggiornamento della prenotazione');
            }
          });
        }
      }
    );
  }

  getPaymentLink() {
    if (environment.production) {
      return 'https://irent-autonoleggio.com/#/reserve?uri='+this.reservationResource.key.uri;
    } else {
      return 'https://dev.irent-autonoleggio.com/#/reserve?uri='+this.reservationResource.key.uri;
    }
  }

  isReservationClosed() {
    return this._reservation.getValue().status == ReservationState.CLOSED;
  }

  getPlaceText(place: string) {
    switch (place) {
      case HEADQUARTER_ADDRESS_VALUE:
        return HEADQUARTER_ADDRESS;
      case STATION_ADDRESS_VALUE:
        return STATION_ADDRESS;
      case AIRPORT_ADDRESS_VALUE:
        return AIRPORT_ADDRESS;
      default: 
        return place;
    }
  }
}
