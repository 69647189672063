import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RESERVATION_DETAIL_PATH } from '../shared/constants';
import { ReservationDetailComponent } from './reservation-detail.component';

const routes: Routes = [
  {
    path: RESERVATION_DETAIL_PATH + '/:reservation_uri',
    component: ReservationDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservationDetailRoutingModule { }
