import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Resource} from '../../core/core.module';
import {FormService} from '../../core/form.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Vehicle, VehicleDamage, VehiclesService} from '../../core/vehicles.service';
import {AuthService} from '../../core/auth.service';

@Component({
  selector: 'app-vehicle-detail-damages-add',
  templateUrl: './vehicle-detail-damages-add.component.html',
  styleUrls: ['./vehicle-detail-damages-add.component.scss']
})
export class VehicleDetailDamagesAddComponent implements OnInit {
  formGroup         : FormGroup;
  vehicle           : Resource;
  showButtonSpinner : boolean = false;

  constructor(private formService: FormService, private dialogRef: MatDialogRef<VehicleDetailDamagesAddComponent>,
              @Inject(MAT_DIALOG_DATA) data, private vehiclesService: VehiclesService, private authService: AuthService) {
    this.formGroup  = formService.vehicleDamageFormGroup;
    this.vehicle    = data.vehicle;
  }

  ngOnInit() {
    this.formGroup.reset();
  }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let date        : number = new Date(this.formGroup.get('date').value).getTime();
      let description : string = this.formGroup.get('description').value;
      this.authService.getAuthenticatedUser().subscribe(
        user => {
          let token           : string              = user.signInUserSession.idToken.jwtToken;
          let damage          : VehicleDamage       = new VehicleDamage();
          damage.date                               = date;
          damage.description                        = description;
          (this.vehicle.content as Vehicle).damages = (this.vehicle.content as Vehicle).damages?
            (this.vehicle.content as Vehicle).damages.concat(damage):[damage];
          this.updateVehicle(token);
        }, error => {
          this.authService.signOut();
        }
      );
    }
  }

  updateVehicle(token: string) {
    this.showButtonSpinner = true;
    this.vehiclesService.updateVehicle(token, this.vehicle).subscribe(
      response => {
        this.showButtonSpinner = false;
        this.dialogRef.close({success: true});
      }, error => {
        this.showButtonSpinner = false;
      }
    );
  }
}
