<div  id="irt-auth-root-layout" 
      class="irt-full-width">
  <div  id="irt-auth-form-layout" 
        class="irt-border-radius">
    <img  class="irt-toolbar-logo" 
          src="../../assets/imgs/irent-logo-dark.png">
    <h2> Benvenuto in IRent </h2>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> email </mat-label>
        <input  matInput 
                type="email" 
                formControlName="email" 
                required>
      </mat-form-field>

      <mat-form-field appearance="outline" 
                      class="irt-full-width">
        <mat-label> password </mat-label>
        <input  [type]="isPasswordVisible? 'text':'password'" 
                matInput 
                formControlName="password" 
                required>
        <mat-icon class="irt-cursor-pointer" 
                  matSuffix
                  (click)="isPasswordVisible = !isPasswordVisible">
          {{ isPasswordVisible ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </mat-form-field>
      <div class="irt-end-row">
        <span class="irt-right-text irt-primary-medium-color irt-cursor-pointer irt-margin-bottom irt-hover-text" 
              (click)="onForgotPssClick()"> 
          Hai dimenticato la password? 
        </span>
      </div>

      <button mat-button 
              class="irt-full-width irt-main-button irt-margin-top"
              [disabled]="showButtonSpinner" 
              [ngClass]="buttonService.getDisabledClass(showButtonSpinner)"
              (click)="onLoginClick()">
        <h3   *ngIf="!showButtonSpinner" 
              class="irt-no-margin"> Accedi </h3>
        <div  class="irt-spinner-layout" 
              *ngIf="showButtonSpinner">
          <mat-spinner  [diameter]="20" 
                        color="accent"> </mat-spinner>
        </div>
      </button>
    </form>
  </div>
</div>
