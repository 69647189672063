import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  loginFormGroup: FormGroup = new FormBuilder().group({
    email                 : ['', [Validators.required, Validators.email]],
    password              : ['', [Validators.required]]
  });

  forgotPssFormGroup: FormGroup = new FormBuilder().group({
    email                 : ['', [Validators.required, Validators.email]]
  });

  forgotPssConfirmFormGroup: FormGroup = new FormBuilder().group({
    code                  : ['', [Validators.required]],
    password              : ['', [Validators.required]],
    passwordConfirm       : ['', [Validators.required]]
  });

  reservationSearchFormGroup: FormGroup = new FormBuilder().group({
    reservations_search   : ['']
  });

  reservationBasicsFormGroup: FormGroup = new FormBuilder().group({
    type                  : [''],
    first_name            : [''],
    last_name             : [''],
    email                 : ['', [Validators.email]],
    phone_prefix          : [''],
    phone                 : [''],
    code                  : [''],
    address               : [''],
    zip_code              : [''],
    city                  : [''],
    province              : [''],
    country               : [''],
    vat                   : [''],
    business_name         : [''],
    dest_code             : [''],
    save                  : [''],
    license_num           : [''],
    license_date          : [''],
    license_exp           : [''],
    license_place         : [''],
  });

  reservationTimeFormGroup: FormGroup = new FormBuilder().group({
    start_address         : ['', [Validators.required]],
    start_date            : ['', [Validators.required]],
    start_hour            : ['', [Validators.required]],
    start_minute          : ['', [Validators.required]],
    end_address           : ['', [Validators.required]],
    end_date              : ['', [Validators.required]],
    end_hour              : ['', [Validators.required]],
    end_minute            : ['', [Validators.required]],
    price                 : [''],
    vehicle_category      : ['']
  });

  reservationVehicleFormGroup: FormGroup = new FormBuilder().group({
    plate                 : ['', [Validators.required]]
  });

  reservationVehicleKmFormGroup: FormGroup = new FormBuilder().group({
    km                    : ['', [Validators.required]]
  });

  reservationDetailStatusFormGroup: FormGroup = new FormBuilder().group({
    status                : ['']
  });

  vehicleDetailStatusFormGroup: FormGroup = new FormBuilder().group({
    status                : ['']
  });

  vehicleSearchFormGroup: FormGroup = new FormBuilder().group({
    vehicles_search       : ['']
  });

  userSearchFormGroup: FormGroup = new FormBuilder().group({
    user_search           : ['']
  });

  vehicleBasicsFormGroup: FormGroup = new FormBuilder().group({
    plate                 : ['', [Validators.required]],
    brand                 : ['', [Validators.required]],
    model                 : ['', [Validators.required]],
    category              : ['', [Validators.required]],
    year                  : ['', [Validators.required]],
    supply                : ['', [Validators.required]],
    price                 : [''],
    link                  : [''],
    km                    : [0]
  });

  vehicleRevisionFormGroup: FormGroup = new FormBuilder().group({
    date                  : [new Date(), [Validators.required]],
    km                    : ['', [Validators.required]],
    note                  : ['', [Validators.required]]
  });

  vehicleDamageFormGroup: FormGroup = new FormBuilder().group({
    date                  : [new Date(), [Validators.required]],
    description           : ['', [Validators.required]]
  });

  reservationsFilterFormGroup       = new FormBuilder().group({
    start_date            : [''],
    end_date              : [''],
    plate                 : ['']
  });

  usersFilterFormGroup              = new FormBuilder().group({
    first_name            : [''],
    last_name             : ['']
  });

  plannerFiltersFormGroup: FormGroup = new FormBuilder().group({
    start_date            : ['', [Validators.required]],
    start_hour            : ['', [Validators.required]],
    start_minute          : ['', [Validators.required]],
    end_date              : ['', [Validators.required]],
    end_hour              : ['', [Validators.required]],
    end_minute            : ['', [Validators.required]]
  });

  reservationServiceFormGroup: FormGroup = new FormBuilder().group({
    name                  : ['', []],
    price                 : ['', []]
  });

  reservationDeductibleFormGroup: FormGroup = new FormBuilder().group({
    name                  : ['', []],
    price                 : ['', []]
  });

  reservationServicesFormGroup: FormGroup = new FormBuilder().group({
    full_kasko            : [0, []],
    unlimited_km          : [0, []],
    limited_km            : [0, []],
    limited_km_quantity   : [0, []],
    damage_deductible     : [0, []],
    theft_deductible      : [0, []],
  });

  notesFormGroup: FormGroup = new FormBuilder().group({
    note: ['', [Validators.required]]
  });

  matcher: FormErrorStateMatcher = new FormErrorStateMatcher();

  validateForm(formGroup: FormGroup) {
    let valid: boolean = true;
    for (let controlName of Object.keys(formGroup.controls)) {
      if (formGroup.get(controlName).invalid) {
        valid = false;
        //formGroup.get(controlName).markAsTouched();
      }
    }
    return valid;
  }
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null,
               form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid
      && (control.dirty || control.touched || isSubmitted));
  }
}
