<div id="irt-reservation-detail-end-contract-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
        style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Contratto finale
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-reservation-detail-end-contract-content-layout">
      <p class="irt-no-margin irt-margin-bottom"> Aggiungi il chilometraggio di rientro </p>
      <form class="irt-form" [formGroup]="formGroup">
          <mat-form-field appearance="outline" class="irt-full-width">
            <mat-label> Chilometraggio di rientro </mat-label>
            <input matInput type="number" formControlName="km" min="0" required>
          </mat-form-field>
  
          <div class="irt-full-width irt-centered-row">
              <button mat-button class="irt-main-button" (click)="onConfirmClick()">
                <h3 class="irt-no-margin"> Conferma </h3>
              </button>
          </div>
      </form>
  </div>
</div>
