<div id="irt-reservation-detail-start-contract-notes-root-layout">
  <div  class="irt-full-width irt-spaced-row irt-padding"
          style="padding-bottom: 0;">
    <h3 class="irt-no-margin irt-primary-medium-color"> 
      Aggiungi delle note al contratto
    </h3> 
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div id="irt-reservation-detail-start-contract-notes-content-layout">
    <form 
      class="irt-form irt-margin-bottom" 
      [formGroup]="formGroup">
      <mat-form-field 
        appearance="outline" 
        class="irt-full-width">
        <mat-label> Nota </mat-label>
        <textarea  
          matInput 
          type="text" 
          formControlName="note"></textarea>
      </mat-form-field>

      <button mat-button 
              class="irt-full-width irt-main-button"
              (click)="onAddNoteClick()">
        <span> Aggiungi nota </span>
      </button>
    </form>

    <div 
      id="irt-reservation-detail-start-contract-notes-list-layout"
      class="irt-margin-bottom irt-primary-medium-background irt-padding irt-hide-scrollbar">
      <p  
        class="irt-centered-text irt-no-margin irt-half-margin-bottom" 
        *ngIf="!notes || !notes.length"> 
        Nessuna nota segnalata 
      </p>
      <p  
        class="irt-no-margin irt-half-margin-bottom" 
        *ngFor="let note of notes; let i = index;">
        <span style="font-size: 24px;"> <b> {{ i + 1 }}. </b> </span> 
        {{ note }};
      </p>
    </div>

    <div class="irt-full-width irt-centered-row">
      <button  
        mat-button 
        [ngClass]="getDisabledClass()" 
        [disabled]="isRequestRunning" 
        (click)="onConfirmClick()">
         <h3 class="irt-no-margin"> Conferma </h3>
       </button>
     </div>
  </div>
</div>
