import { Component, OnInit } from '@angular/core';
import { NavbarItem } from '../shared/constants';
import { NavbarItemName } from '../shared/translations';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  navbarItem: NavbarItem;

  constructor() {
    this.navbarItem       = new NavbarItem();
    this.navbarItem.name  = NavbarItemName.USERS;
  }

  ngOnInit() { }
}
