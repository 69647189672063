<div id="irt-planner-section-root-layout" class="irt-full-height">
  <div class="irt-full-width irt-spaced-row">
    <h1 class="irt-no-margin"> Rent planner </h1>
  </div>

  <div class="irt-double-margin-top" *ngIf="!showLoadButtonSpinner">
    <app-planner-filters [formGroup]="formGroup" (searchClick)="onSearchClick()"></app-planner-filters>
  </div>

  <div  class="irt-double-margin-top" 
        *ngIf="!showLoadButtonSpinner && vehicles && vehicles.length>0">
    <app-planner-table [vehicles]="vehicles"></app-planner-table>
  </div>

  <div class="irt-centered-column irt-double-margin-top" 
       *ngIf="!showLoadButtonSpinner && vehicles && vehicles.length<=0">
    <h3> Nessun veicolo disponibile </h3>
  </div>

  <div class="irt-centered-column irt-double-margin-top" 
       *ngIf="!showLoadButtonSpinner && !vehicles">
    <h3> Nessun filtro selezionato </h3>
  </div>

  <div  class="irt-spinner-layout irt-full-width irt-centered-row"
        style="height: calc(100% - 104px)" 
        *ngIf="showLoadButtonSpinner">
    <div  class="irt-padding irt-border-radius" 
          style="background: #F4F4F4;">
      <mat-spinner  [diameter]="30" 
                    color="accent"> </mat-spinner>
    </div>
  </div>
</div>
