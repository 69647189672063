import {Component, Input, OnInit} from '@angular/core';
import {Resource} from '../../core/core.module';
import {Storage} from 'aws-amplify';
import {Vehicle} from '../../core/vehicles.service';

@Component({
  selector: 'app-vehicle-detail-gallery',
  templateUrl: './vehicle-detail-gallery.component.html',
  styleUrls: ['./vehicle-detail-gallery.component.scss']
})
export class VehicleDetailGalleryComponent implements OnInit {
  @Input() vehicle  : Resource;
  vehicleContent    : Vehicle;
  vehicleImageUrl   : string;

  constructor() {}

  ngOnInit() {
    this.vehicleContent = this.vehicle.content as Vehicle;
    Storage.get((this.vehicle.content as Vehicle).img_url, {level: 'public'}).then(result => {
      this.vehicleImageUrl = result.toString();
    }).catch(err => {
      //this.vehicleImageUrl = 'https://irent-front-storage.s3.amazonaws.com/alfa-giulia-2016.jpeg';
    });
  }
}
