import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Resource } from '../../core/core.module';
import {
  RESERVATION_DETAIL_PATH,
  ReservationState,
} from '../../shared/constants';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormService } from '../../core/form.service';
import { FormGroup } from '@angular/forms';
import { CustomerType } from 'src/app/core/customers.service';

@Component({
  selector: 'app-reservations-table',
  templateUrl: './reservations-table.component.html',
  styleUrls: ['./reservations-table.component.scss'],
})
export class ReservationsTableComponent implements OnInit {
  @Input() _reservations: BehaviorSubject<Resource[]>;
  @Output() refreshClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() reloadReservations: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() filtersClick: EventEmitter<void> = new EventEmitter<void>();
  formGroup: FormGroup;
  updateTime: number;
  columnsToDisplay: string[] = [
    'id',
    'user',
    'email',
    'phone',
    'startDate',
    'endDate',
    'vehicle',
    'plate',
    'amount',
    'status',
  ];
  dataSource = new MatTableDataSource<Resource>([]);
  privateCustomerType: CustomerType = CustomerType.PRIVATE;
  companyCustomerType: CustomerType = CustomerType.COMPANY;

  constructor(private router: Router, private formService: FormService) {
    this.formGroup = this.formService.reservationSearchFormGroup;
  }

  ngOnInit() {
    this._reservations.subscribe((reservations) => {
      if (reservations) {
        this.dataSource.data = reservations;
        this.updateTime = new Date().getTime();
      }
    });
  }

  onReservationClick(reservation: Resource) {
    this.router.navigate([RESERVATION_DETAIL_PATH + '/' + reservation.key.uri]);
  }

  onRefreshClick() {
    this.refreshClick.emit();
  }

  onInputChange(event) {
    this.reloadReservations.emit(
      this.formGroup.get('reservations_search').value
    );
  }

  onSearchChange(event) {
    //console.log(event.data);
  }

  onFiltersClick() {
    this.filtersClick.emit();
  }

  getStatusClass(reservation: Resource) {
    return {
      'irt-reservation-pending-status':
        reservation.status == ReservationState.PENDING,
      'irt-reservation-confirmed-status':
        reservation.status == ReservationState.CONFIRMED,
      'irt-reservation-running-status':
        reservation.status == ReservationState.RUNNING,
      'irt-reservation-closed-status':
        reservation.status == ReservationState.CLOSED,
      'irt-reservation-expired-status':
        reservation.status == ReservationState.EXPIRED,
    };
  }
}
