import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FormService } from 'src/app/core/form.service';

@Component({
  selector: 'app-users-filters',
  templateUrl: './users-filters.component.html',
  styleUrls: ['./users-filters.component.scss']
})
export class UsersFiltersComponent implements OnInit {
  formGroup: FormGroup;

  constructor(private dialogRef: MatDialogRef<UsersFiltersComponent>, private formService: FormService) { 
    this.formGroup = this.formService.usersFilterFormGroup;
  }

  ngOnInit() { }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    const firstName : number = this.formGroup.get('first_name') .value;
    const lastName  : number = this.formGroup.get('last_name')  .value;
    this.dialogRef.close({
      first_name  : firstName,
      last_name   : lastName
    });
  }

  onFiltersRemoveClick() {
    this.formGroup.reset();
  }
}
